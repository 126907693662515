
function TestimonialCard(props) {
 
  return (
    <div className="testimonial-card">
        <div className="testimonial-card-info">
         
          <div className="profile-info px-2">
            
              <p className="review-text">{props.clientReview}</p>
              <img src={props.clientImg} alt="" />
              <h5 className="name">{props.clientName}</h5>
          </div>
          
        </div>
    </div>
  )
}

export default TestimonialCard