import React from 'react'
import { useNavigate } from 'react-router-dom'

function truncateText(text, maxLength) {
  if (text.length <= maxLength) {
    return text;
  } else {
    return text.substring(0, maxLength - 3) + '...';
  }
}



function ServiceCard({
    icon,
    heading,
    content,
    btnText="Learn More",
    btnRef="/"
}) {
    const navigate= useNavigate()
    const para = truncateText(content,100)
  return (
    <div className="service-card py-4">
    <div className="service-card-info">
      
      <div className="profile-info px-2 d-flex flex-column gap-3 text-center ">
        
        <div className='icon-container'>
          <img src={icon} alt="" className='icon'/>

        </div>
          <h3 className='body-heading-w mt-3' style={{fontSize:"20px"}}>{heading}</h3>
          <p className="review-text body-paragraph px-4">{para}</p>
          <button className='genral-btn' onClick={()=>{navigate(btnRef)}}>{btnText}</button>
      </div>
     
    </div>
</div>
  )
}

export default ServiceCard