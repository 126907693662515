import { useNavigate } from "react-router-dom"

function CardBox(props) {
  const navigate = useNavigate()
  return (
    <div className="card-box">
        <img src={props.cardImg} alt="someImage" />
        <div className="card-text-content">
          <div className="top-links">
            <div className="d-flex align-items-center justify-content-start gap-1">
              <img src="/images/icons/person.svg" className="m-0"/>
              <p className="m-0 ">Admin</p>
            </div>
            <div className="d-flex align-items-center justify-content-start gap-1">
              <img src="/images/icons/comment.svg" className="m-0"/>
              <p className="m-0">Comment</p>
            </div>
          </div>
          <h5>{props.title}</h5>
          <div className="body-paragraph" dangerouslySetInnerHTML={{__html:props.content}}></div>
          <a onClick={()=>{props.redirectionFunction(props.anchor)}} className="read-more-anchor">Read More</a>
        </div>
    </div>
  )
}

export default CardBox