import React from 'react'
import HeroBanner from '../components/Common Banner Component/HeroBanner'
import PopularBlogs from '../components/PopularBlogs/PopularBlogs'

function blogMain() {
  return (
    <>
        <HeroBanner
            Heading="NEWS & UPDATES"
            subHeading=""
            spanHeading=""
            Paragraph=""
            btnText=""
            bgImg="/images/creative/blog-hero-bg.jpg"
            darkBg={true}
            headingCenter={true}
            btn={false}
            socialLinks={false}
            serviceForm={false}
            
        />
        <PopularBlogs/>
        
    </>
  )
}

export default blogMain