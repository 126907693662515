import React,{useState} from 'react'
import "./Footer.css"
import { Link, useNavigate } from 'react-router-dom'
import {Spinner} from "react-bootstrap"
import axios from "axios"


function Footer() {

    const [email, setEmail] = useState("");
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate()

    const formSubmitHandler = (e) => {
        e.preventDefault();
        setLoading(true);
        axios
          .post("/send-newsletter", {
            email: email,
            formType: "Newsletter",
          })
          .then((response) => {
            if (response.data.msg === "success") {
              setError(false);
              setSuccess("Newsletter Subscribed Successfully!");
              setLoading(false);
              setEmail("");
            } else if (response.data.msg === "fail") {
              setSuccess(false);
              setError("Email failed to send, try again by reloading the page.");
              setLoading(false);
              setEmail("");
            }
          });
      };

  return (
    <div className='footer-container pt-5 genral-padding-space'>
        <div className='container'>
            <div className='row'>
                <div className='col-lg-5'>
                    
                    <div className='text-content gap-3 '>
                        <img src="/images/icons/logo.png" onClick={()=>{navigate("/home")}} style={{cursor:"pointer"}}/>
                        <p className='body-paragraph' style={{color:"white"}}>Our team of experienced chartered accountants have been a pillar of support for businesses and individuals alike for over 5 decades.</p>
                        <form className='inp-container' onSubmit={formSubmitHandler}>
                            <input 
                                required
                                type="email"
                                value={email}
                                maxLength="56"
                                onChange={(e) => setEmail(e.target.value)}
                                placeholder='Your Email'
                            />
                            <button className='inp-btn' type="submit">Newsletter
                            {loading ? (
                                <Spinner
                                    animation="border"
                                    role="status"
                                    style={{ color: "white" ,width:"15px",height:"15px",marginLeft:"10px"}}
                                />
                            ) : (
                            success ? (
                                <div >
                                {setLoading(false)}
                                {alert(success)}
                                {setSuccess(false)}
                                </div>
                            ) : (
                                error && (
                                <div>
                                    {setLoading(false)}
                                    {alert(error)}
                                    {setError(false)}
                                </div>
                                )
                            )
                            )}
                            
                            </button>
                        </form>
                    </div>

                </div>
                <div className='col-lg-7'>
                    <div className='container link-list-container pt-4'>
                        <div className='row gy-4 text-center text-lg-start'>
                            <div className='col-lg-4 col-md-6'>

                                <div className='link-list d-flex flex-column mx-auto'>
                                    <h3 className='list-heading mb-4'>COMPANY</h3>
                                    
                                    <Link to={"/"}>Home</Link>
                                    <Link to={"/about"}>About</Link>
                                    <Link to={"/service"}>Services</Link>
                                    <Link to={"/careers"}>Careers</Link>
                                    <Link to={"/contact"}>Contact Us</Link>
                                </div>

                            </div>
                            <div className='col-lg-4 col-md-6'>

                                <div className='link-list d-flex flex-column'>
                                    <h3 className='list-heading mb-4'>SERVICES</h3>
                                    
                                    <Link to={"/bookkeeping"}>
                                        <p className="mb-0">Bookkeeping</p>
                                    </Link>
                                    <Link to={"/accounting-solutions"}>
                                        <p className="mb-0">Accounting</p>
                                    </Link>
                                    <Link to={"/payroll-management"}>
                                        <p className="mb-0">Payroll</p>
                                    </Link>
                                    <Link to={"/hst-returns"}>
                                        <p className="mb-0">HST Returns</p>
                                    </Link>
                                    <Link to={"/corporate-taxes"}>
                                        <p className="mb-0">Corporate Taxes</p>
                                    </Link>
                                </div>

                            </div>
                            <div className='col-xl-4 col-12'>

                                <div className='link-list'>
                                    <h3 className='list-heading mb-4'>CONTACT US</h3>
                                    
                                    <a href='tel:+1 905-354-0708' className='d-flex align-items-start justify-content-center justify-content-lg-start gap-2 mb-3'><img src='/images/icons/phone.svg'  style={{marginTop:"5px"}}/>+1 905-354-0708</a>
                                    <p className='d-flex align-items-start justify-content-center justify-content-lg-start gap-2 mb-3'><img src='/images/icons/email.svg'  style={{marginTop:"5px"}}/>info@accountaxinternational.com</p>
                                    <p className='d-flex align-items-start justify-content-center justify-content-lg-start gap-2'><img src='/images/icons/location.svg' style={{marginTop:"5px"}} />4838 Dorchester Rd, Niagara Falls, ON L2E 6N9</p>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </div>
        <div className='line-seprator'></div>
        <div className='bottom-section d-flex flex-column flex-md-row align-items-center justify-content-between container'>
            <p>Made With ❤️ By <a href="https://www.creativesquad.ca/" target="blank" style={{color:"#e4e4e4",textDecoration:"none"}}>Creative Squad</a></p>
            <p>© 2024 by Accountax International Inc.</p>

        </div>
            {/* <div className="lower-link-list mx-auto ">
                <img src="images/icons/facebook-icon.png" alt="" />
                <img src="images/icons/linked-icon.png" alt="" />
                <img src="images/icons/twitter-icon.png" alt="" />
            </div> */}

    </div>
  )
}

export default Footer