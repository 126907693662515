import React from 'react'
import HeroBanner from '../components/Common Banner Component/HeroBanner'
import Footer from '../components/Footer/Footer'

function NotFound() {
  return (
    <>
   
        <HeroBanner
            subHeading=""
            Heading="404 Not Found"
            nextLineSpanHeading=""
            Paragraph=""
            btnText=""
            bgImg="/images/creative/plane-hero-bg.png"
            btn={false}
            btnRef="/"
            bgEffect={false}
            darkBg={true}
            isCheveron={false}
            marginBottom={false}
        />
        
    </>
  )
}

export default NotFound