import React,{useEffect, useState } from 'react'
import HeroBanner from '../components/Common Banner Component/HeroBanner'
import BlogDetails from '../components/BlogDetails/BlogDetails'
import MostCommonBlog from '../components/MostCommonBlog/MostCommonBlog'
import { useParams } from "react-router";
import axios from "axios";
import { Spinner } from "react-bootstrap";
import { Helmet } from "react-helmet";

function BlogInner() {
  const [blogsData, setBlogsData] = useState();
  const [recentBlogs, setRecentBlogs] = useState([]);

  const [loading, setLoading] = useState(true);

  const { id } = useParams();

  let blogAPICalledId = false;
  let allBlogsCalled = false;

  const getAllBlogs = async () => {

    setLoading(true);
    axios
      .get(`/blogs`, {})
      .then((res) => {
        if (res.data.status === "success") {
          let Updated_recent_blogs = [];
          let blog = res.data.data.find((item) => item.slug_url == id);
          setBlogsData( (prev)=>{return blog});
          console.log(blogsData);

          res.data.data.forEach((item) => {
            Updated_recent_blogs.push({
              id: item.blog_id,
              slug_url: item.slug_url,
              logo: item.blog_image,
              blog_description: item.title,
              date: item.published_date,
            });
          });
          setRecentBlogs(Updated_recent_blogs.slice(0, 3));

          setLoading(false)


        }
      })
      .catch((err) => {
        console.log(err);
        console.log("err");
        setBlogsData(null)
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllBlogs();
  }, [id]);


  return (
    <>
    {loading  ? (
          <div
            style={{ width: "100%", height: "100vh" }}
            className="d-flex justify-content-center align-items-center"
          >
            <Spinner
              style={{ color: "black", width: "120px", height: "120px" }}
            />
          </div>
        ) :  (
          
          <>
          {blogsData &&
            <>
            <Helmet>
              {blogsData?.metaTitle && <title>{blogsData.metaTitle}</title>}
              {blogsData?.metaDescription && <meta name='description' content={blogsData.metaDescription} />}
              {blogsData?.canonicalLink && <link rel="canonical" href={blogsData.canonicalLink}></link>}
            </Helmet>
          <HeroBanner
              Heading={blogsData.title}
              subHeading=""
              Paragraph=""
              btnText=""
              bgImg=""
              darkBg={true}
              headingCenter={true}
              btn={false}
              socialLinks={false}
              serviceForm={false}
              
          />
          
          <BlogDetails
            blogsData={blogsData.blogs_content}
            text={blogsData.brief_paragraph}
            bannerImg={blogsData.blog_image}
          />
          {
            recentBlogs &&
            <MostCommonBlog
              recentBlogs={recentBlogs}
            />
          }
          </>
        }
          </>
        )}
    </>
  )
}

export default BlogInner