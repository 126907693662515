import "./Testimonials.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import {Autoplay} from "swiper/modules"
import TestimonialCard from "./TestimonialCard";

function Testimonials() {
  const blogData = [
    {
      clientName: "Sarah K., Owner, Heritage Furnishings",
      clientImg: "/images/creative/testimonial-user.png",
      clientReview:
        "Their strategic guidance has been instrumental in the growth of our family-owned business. Highlyrecommended!",
    },
    {
      clientName: "Michael L., Small Business Owner",
      clientImg: "/images/creative/testimonial-user.png",
      clientReview:
        "Efficient, reliable, and knowledgeable – that's how I would describe Accountax International Inc",
    },
    {
      clientName: "Emily R., Entrepreneur and Investor",
      clientImg: "/images/creative/testimonial-user.png",
      clientReview:
        "Accountax International Inc. not only protected my assets but also provided proactive strategies for wealth preservation.",
    },
  ];

  const handlePrevButtonClick = () => {
    const swiperInstance = document.querySelector('#articles-cards-slider').swiper;
    swiperInstance.slidePrev();
  };
  
  const handleNextButtonClick = () => {
    const swiperInstance = document.querySelector('#articles-cards-slider').swiper;
    swiperInstance.slideNext();
  };

  return (
    <div className="Testimonials-container standard-padding-space">
      <div className="container">
    <div className="row d-flex align-items-center">
      <div className="col-md-12">
        <h3 className="text-center body-heading-w genral-padding-space mb-4"><span>TESTIMONIALS</span></h3>
        <div style={{position:"relative",display:"flex"}}>
        <div className="swiper-button-prev-test swiper-button-test d-none d-md-flex" onClick={handlePrevButtonClick} style={{left:0}}>
                  <img src="/images/icons/arrow-left.svg" className="arrow-img"/>
                </div>
        <Swiper
            spaceBetween={50}
            slidesPerView={1}
            autoplay={{
              delay: 1500,
              disableOnInteraction: false,
            }}
            loop={true}
            modules={[Autoplay]}
            navigation={{
              nextEl: '.swiper-button-next-test',
              prevEl: '.swiper-button-prev-test',
            }}
            pagination={{ clickable: true }}
            observer ={true}
            observeParents={true}
            parallax={true}
            className="testimonials-list"
            id="articles-cards-slider"
            >
                 
              {
                blogData.map((clientData,ind)=>{
                  return(<>
                    <SwiperSlide key={ind}>
                      <TestimonialCard clientImg={clientData.clientImg} clientName={clientData.clientName} clientReview={clientData.clientReview} handleNextButtonClick={handleNextButtonClick} handlePrevButtonClick={handlePrevButtonClick}/>
                    </SwiperSlide>
                     </>)
                })
              }
        </Swiper>
              <div className="swiper-button-next-test swiper-button-test d-none d-md-flex" onClick={handleNextButtonClick} style={{right:0}}>
                <img src="/images/icons/arrow-right.svg" className="arrow-img"/>
              </div>
            </div>
      </div>
      </div>
  </div>
</div>
  )
}

export default Testimonials