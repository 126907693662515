import React,{useState} from "react";
import "./HeroBanner.css";
import Header from "../Header/Header";
import axios from "axios"
import {Spinner} from "react-bootstrap"

const HeroBanner = ({
  Heading,
  subHeading,
  spanHeading="",
  spanHeadingBlue="",
  preSpanHeading="",
  Paragraph,
  btnText,
  bgImg,
  btn=false,
  socialLinks=false,
  serviceForm=false,
  darkBg=false,
  headingCenter=false,
  customHeadingSize=null,
  innerService=null,
  bgEffect=true,
  bgVideo=false,
  marginBottom=true
}) => {

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const formSubmitHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    axios
      .post("/send-contact-form-main", {
        email: email,
        name:name,
        message:message,
        phoneNum:phone,
        formType: "Message genrated through Main Contact page's form",
      })
      .then((response) => {
        if (response.data.msg === "success") {
          setError(false);
          setSuccess("Message sent Successfully!");
          setLoading(false);
          setEmail("");
        } else if (response.data.msg === "fail") {
          setSuccess(false);
          setError("Failed to send message, try again by reloading the page.");
          setLoading(false);
          setEmail("");
        }
      });
  };
  
  const handlePhoneChange = (e) => {
    const numericValue = e.target.value.replace(/\D/g, ''); 
    const maxLength = 25;


    if (numericValue.length <= maxLength) {
      setPhone(numericValue);
    }
  };
  const handleNameChange = (e) => {
    const inputValue = e.target.value;
    const regex = /^[a-zA-Z\s]*$/;

    if (regex.test(inputValue) || inputValue === '') {
      setName(inputValue);
    }
  };


  // Accountax-Video
  return (
    <div
      className={`common_hero_section ${bgVideo && "bg-video"} ${marginBottom && "mb-5"}`}
      style={bgVideo ?{}:{ backgroundImage: `url(${bgImg})` }}

    >
      { bgVideo && <video autoPlay loop muted className="hero-bg-video">
        <source src={bgVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>}
      <div className={`${bgEffect && "bg-effect"} h-100`}>
      <Header darkBg={darkBg}/>

      <div className={`container h-100 d-flex align-items-center ${headingCenter && "justify-content-center"} py-5 `}>
        <div 
          className={`row gy-2 ${serviceForm && "align-items-center gy-4"} `}
          style={serviceForm ? {paddingBottom:"120px"} : {}}
        >
          <div
            className={
              serviceForm
                ? "col-md-6 "
                : "col-12 hero_content_wrapper"
            }
          >
            <div className="hero-text-content px-1 ">
            <span className="">{subHeading}</span>
            <h1 className="hero-section-heading" style={darkBg ? {color:"#FFFFFF",maxWidth:"100%",textAlign:`${headingCenter?"center":"start"}`, fontSize:`${customHeadingSize ? customHeadingSize: "50px" }`} : {fontSize:`${customHeadingSize ? customHeadingSize: "55px" }`}}>
              {preSpanHeading}
              <span className="color-yellow">{spanHeading}</span>
              <span className="heading-color-change">{spanHeadingBlue}</span>
              {Heading}
            </h1>
            {innerService && <h3 className="body-heading d-flex align-items-center justify-content-center gap-2 mt-3 " style={{color:"white",fontSize:"20px"}}><span style={{color:"#E1A92A"}}>SERVICES</span> <img src="/images/icons/hero-service-inner-arrow.svg"/> <p className="mb-0 mobile-min-width " >{innerService}</p></h3>}
            <p className="body-paragraph my-3 my-md-4 " style={darkBg ? {color:"#FFFFFF"} : {}}>{Paragraph}</p>
            {btn && <button className="hero-btn genral-btn d-flex align-items-center gap-2">{btnText} <img src="/images/icons/button-arrow-right.svg" /></button>}
            {socialLinks && (
              <div className="social-links d-flex flex-column gap-3">
                <div className="first-row flex-column flex-xl-row d-flex gap-3">
                  <div className="link-container d-flex align-items-center gap-1">
                    <img src="/images/icons/social-phone.png" />
                    <a href="tel:+1 905-354-0708" className="mb-0">+1 905-354-0708</a>
                  </div>
                  <div className="link-container d-flex align-items-center gap-1">
                    <img src="/images/icons/social-mail.png" />
                    <p className="mb-0">info@accountaxinternational.com</p>
                  </div>
                 
                </div>
                <div className="link-container d-flex align-items-center gap-1">
                  <img src="/images/icons/social-location.png" />
                  <p className="mb-0">4838 Dorchester Rd, Niagara Falls, ON L2E 6N9</p>
                </div>
                <div className="link-container d-flex align-items-center gap-1">
                  <img src="/images/icons/social-time.png" />
                  <p className="mb-0">Monday to Friday (7:30 AM - 7:30 PM)</p>
                </div>
              </div>
            )}
          </div>
          </div>
          {serviceForm && (
            <div className="col-md-6">
              <form className="service-form d-flex flex-column p-4 py-5" onSubmit={formSubmitHandler}>
                <h3 className="body-heading" style={{ color: "white",fontSize:"32px",fontWeight:"600" }}>
                Let’s work together
                </h3>
                <p
                  className="body-paragraph text-start mt-3"
                  style={{ color: "white" }}
                >
                 Let's turn your financial goals into a reality!
                </p>
                <input 
                   type="text" 
                   value={name}
                   maxLength="36"
                   required
                   onChange={handleNameChange} 
                  placeholder="Your Full Name" 
                />
                <input 
                  type="email" 
                  value={email}
                  maxLength="66"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Your Email Address" 
                />
                <input 
                  type="tel" 
                  value={phone}
                  maxLength="25"
                  required
                  onChange={handlePhoneChange} 
                  placeholder="Phone Number" 
                />
                <input 
                  type="text" 
                  value={message}
                  maxLength="350"
                  required
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Your Message" 
                />
                <button className="service-from-btn" type="submit">Submit
                {loading ? (
                                    <Spinner
                                        animation="border"
                                        role="status"
                                        style={{ color: "white" ,width:"15px",height:"15px",marginLeft:"10px"}}
                                    />
                                ) : (
                                success ? (
                                    <div>
                                    {setLoading(false)}
                                    {alert(success)}
                                    {setSuccess(false)}
                                    </div>
                                ) : (
                                    error && (
                                    <div>
                                        {setLoading(false)}
                                        {alert(error)}
                                        {setError(false)}
                                    </div>
                                    )
                                )
                                )}
                </button>
              </form>
            </div>
          )}
        </div>
      </div>
    </div>
    </div>
  );
};

export default HeroBanner; 