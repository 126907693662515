import React from 'react';
import './PartnershipAwards.css';

const PartnershipAwards = () => {
    return (
        <div className='partnership_awards'>
            <div className='container'>
                <div className="flex-container">
                    <div className="flex-item"><img className="img-fluid" src="/images/icons/partner-logo-1.png" alt="sponsor icon" /></div>
                    <div className="flex-item"><img className="img-fluid" src="/images/icons/partner-logo-2.png" alt="sponsor icon" /></div>
                    <div className="flex-item"><img className="img-fluid" src="/images/icons/partner-logo-3.png" alt="sponsor icon" /></div>
                    <div className="flex-item"><img className="img-fluid" src="/images/icons/partner-logo-4.png" alt="sponsor icon" /></div>
                    <div className="flex-item"><img className="img-fluid" src="/images/icons/partner-logo-5.png" alt="sponsor icon" /></div>
                </div>
            </div>
        </div>
    )
};

export default PartnershipAwards;