import React,{useState} from 'react'
import "./ContactForm.css"
import axios from "axios"
import {Spinner} from "react-bootstrap"

function ContactForm() {

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);
  
    const formSubmitHandler = (e) => {
      e.preventDefault();
      setLoading(true);
      axios
        .post("/send-quote", {
          email: email,
          name:name,
          message:message,
          formType: "Message genrated through Contact form",
        })
        .then((response) => {
          if (response.data.msg === "success") {
            setError(false);
            setSuccess("Message sent Successfully!");
            setLoading(false);
            setEmail("");
          } else if (response.data.msg === "fail") {
            setSuccess(false);
            setError("Failed to send message, try again by reloading the page.");
            setLoading(false);
            setEmail("");
          }
        });
    };
   
    const handleNameChange = (e) => {
      const inputValue = e.target.value;
      const regex = /^[a-zA-Z\s]*$/;
  
      if (regex.test(inputValue) || inputValue === '') {
        setName(inputValue);
      }
    };
  
  
  return (
    <div className='contact-form-container standard-padding-space mb-4'>
        
        <div className='container'>
            <div className='row'>
                <div className='col-md-6'>
                    <img src='/images/creative/contact-form.png' className='img-fluid' />
                </div>
                <div className='col-md-6 d-flex align-items-center justify-content-center gy-4'>
                    <div className='form-container'>
                        <div className='text-content'>
                            <h3 className='body-heading-b'>Stuck In Numbers?</h3>
                            <p className='body-paragraph my-3'>Whether you're a business owner seeking financial guidance or an individual looking to optimize personal finances, Accountax International Inc. is ready to partner with you on your journey to financial success.</p>
                        </div>    
                        <form className="contact_form_form_container mt-5" onSubmit={formSubmitHandler}> 
                            <div className="input-container">
                                <input
                                    type="text" 
                                    value={name}
                                    required
                                    maxLength="36"
                                    onChange={handleNameChange}
                                    placeholder="Name"
                                />
                            </div>
                            <div className="input-container">
                                <input
                                    type="email" 
                                    value={email}
                                    maxLength="66"
                                    required
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="Email"
                                />
                            </div>
                            <div className="input-container">
                                <textarea
                                    value={message}
                                    maxLength="350"
                                    required
                                    onChange={(e) => setMessage(e.target.value)}
                                    rows={4} 
                                    placeholder="Write Message"
                                />
                            </div>
                            <div className="button-container">
                                <button type="submit">Submit
                                {loading ? (
                                    <Spinner
                                        animation="border"
                                        role="status"
                                        style={{ color: "white" ,width:"15px",height:"15px",marginLeft:"10px"}}
                                    />
                                ) : (
                                success ? (
                                    <div>
                                    {setLoading(false)}
                                    {alert(success)}
                                    {setSuccess(false)}
                                    </div>
                                ) : (
                                    error && (
                                    <div>
                                        {setLoading(false)}
                                        {alert(error)}
                                        {setError(false)}
                                    </div>
                                    )
                                )
                                )}
                                </button>
                            </div>
                        </form>
                    </div>
                    
                </div>
            </div>
        </div>

    </div>
  )
}

export default ContactForm