import React,{useState,useEffect} from 'react'
import "./PopularBlogs.css"
import BlogCard from './BlogCard'
import PopularBlogCard from './PopularBlogCard'
import axios from 'axios'
import { Spinner } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'

const blogData = [
  {
    catagory: "Knowledge",
    date: "Apr 10, 2023",
    title:"The ABCs of Efficient Bookkeeping for Small Businesses",
    content:
      "Whether you're a startup or an established small business, this blog provides actionable insights to maintain accurate financial records and enhance yo...",
    img: "/images/creative/card-img.jpg",
    blogRoute: "bloginner",
  },
  {
    catagory: "Knowledge",
    date: "Apr 10, 2023",
    title:"Tax Planning Strategies for Sustainable Corporate Growth",
    content:
      "Our blog post takes you through the nuances of strategic tax planning, offering insights on optimizing your corporate tax position while ensuring compliance.",
    img: "/images/creative/card-img.jpg",
    blogRoute: "bloginner",
  },
  {
    catagory: "Knowledge",
    date: "Apr 10, 2023",
    title:"International Business Expansion: Key Considerations for a...",
    content:
      "Thinking of expanding your international business into Canada? Our blog provides a comprehensive guide on key considerations for a seamless entry into the...",
    img: "/images/creative/card-img.jpg",
    blogRoute: "bloginner",
  },
];
const popularBlogDate = [
  {
    img: "/images/creative/popular-img.jpg",
    title: "Tax Planning Strategies for Sustainable...",
    date: "Apr 10, 2023",
  },
  {
    img: "/images/creative/popular-img.jpg",
    title: "The ABCs of Efficient Bookkeeping for...",
    date: "Apr 10, 2023",
  },
  {
    img: "/images/creative/popular-img.jpg",
    title: "International Business Expansion: Key Con...",
    date: "Apr 10, 2023",
  },
];

let defaultBlogObj = []

function PopularBlogs() {
  const [blogs, setBlogs] = useState([]);
    const [recentBlogs, setRecentBlogs] = useState([]);
  
    const [loading, setLoading] = useState(true);
  
    const navigate = useNavigate();
  
    let blogAPICalled = false;
  
    useEffect(() => {
      getAllBlogsListing();
    }, []);
  
    const getAllBlogsListing = () => {
      if (blogAPICalled) return;
      blogAPICalled = true;
  
      setLoading(true);
      axios
        .get("/blogs", {})
        .then((res) => {
          if (res.data.status === "success") {
            let Updated_data_Blogs = [];
            let Updated_recent_blogs = [];
            res.data.data.forEach((item) => {
              Updated_data_Blogs.push({
                id: item.blog_id,
                main_heading: item.title,
                paragraph: item.brief_paragraph,
                logo: "/favicon.png",
                entity: "Creative Squad",
                date: item.published_date,
                slug_url: item.slug_url,
                category: item.category,
                blog_creative: item.blog_image,
              });
            });
            res.data.data.forEach((item) => {
              Updated_recent_blogs.push({
                id: item.blog_id,
                slug_url: item.slug_url,
                logo: item.blog_image,
                blog_description: item.title,
                date: item.published_date,
              });
            });
            setRecentBlogs(Updated_recent_blogs.slice(0, 3));
            setBlogs(Updated_data_Blogs);
            defaultBlogObj = Updated_data_Blogs;
            setLoading(false);
          }
            
        })
        .catch((err) => {
          console.log("eror =>",err);
          setLoading(false);
        });
    };
  
    const convertToSlug = (str) => {
      return str.toLowerCase().replace(/\s+/g, "-");
    };
  
    const redirectUserToBlog = (id) => {
      if (!id) return;
      let slug = convertToSlug(id);
  
      navigate(`/news/${slug}`);
    };

  return (
    <div className='popular-blog-container standard-padding-space'>
        <div className='container'>
        {loading ? 
          (<div className="w-100 text-center mb-5">
            <Spinner
              style={{ color: "#263A5D", width: "120px", height: "120px" }}
            />
          </div>):
          (
            <div className='row justify-content-between'>
                <div className='col-md-6 '>
                    

                    {
                        blogs.map((blog)=>{
                            return(
                                <BlogCard
                                    catagory={"Knowledge"}
                                    title={blog.main_heading}
                                    anchor={blog.slug_url}
                                    redirectionFunction={redirectUserToBlog}
                                    date={blog.date}
                                    content={blog.paragraph}
                                    img={blog.blog_creative}
                                />)
                        })
                    }
                   

                </div>
                <div className='col-md-5'>
                    <h3 className='body-heading-b text-md-start text-center'>Most Popular</h3>

                    {
                        recentBlogs.map((blog)=>{
                            return(
                                <PopularBlogCard
                                    img={blog.logo}
                                    title={blog.blog_description}
                                    date={blog.date}
                                    anchor={blog.slug_url}
                                    redirectionFunction={redirectUserToBlog}
                                />
                            )
                        })
                    }
                    
                </div>
            </div>
          )}
        </div>
    </div>
  )
}

export default PopularBlogs