import React from 'react'
import HeroBanner from '../components/Common Banner Component/HeroBanner'
import ContactForm from '../components/ContactForm/ContactForm'
import FaqSection from "../components/FaqSection/FaqSection"
import AboutIcons from "../components/AboutIcons/AboutIcons"
import ServicesInnerTextContent from '../components/ServicesInnerTextContent/ServicesInnerTextContent'



const faqList=[
    {
        title:"Certain pests can be very dangerous?",
        content:"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse"
    },
    {
        title:"Certain pests can be very dangerous?",
        content:"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse"
    },
    {
        title:"Certain pests can be very dangerous?",
        content:"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse"
    },
    {
        title:"Certain pests can be very dangerous?",
        content:"Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse"
    },
   
]


function ServiceInner({
    indData
}) {
  return (
    <>
    <HeroBanner
        Heading={indData.serviceName}
        subHeading=""
        spanHeading=""
        Paragraph=""
        btnText=""
        bgImg={indData.bannerImg}
        darkBg={true}
        headingCenter={true}
        btn={false}
        socialLinks={false}
        serviceForm={false}
        innerService={indData.serviceName}
        customHeadingSize={"2.3rem"}
    />
    <div style={{height:"50px"}}></div>


    <ServicesInnerTextContent
        heading={indData.ServicesInnerTextContent.heading}
        thumbnailImg={indData.thumbnailImg}
        content={indData.ServicesInnerTextContent.content}
        emphasisText={indData.ServicesInnerTextContent.emphasisText}
        whatIntro={indData.ServicesInnerTextContent.whatIntro}
        whatList={indData.ServicesInnerTextContent.whatList}

    />
    { indData.faq && 
        <FaqSection
        heading="FREQUENTLY ASKED QUESTIONS"
        subHeading=""
        faqData={indData.faq}
    />}
    {/* <ServicesInnerTextContent
        heading={"SMART FINANCIAL SERVICES FOR FIRMS"}
        content={`Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.\n Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.`}
        emphasisText={`Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer industry's standard dummy text ever since the 1500s, when an unknown printer`}
        whatIntro="Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,"
        whatList={whatList}

    />
    <FaqSection
        heading="FREQUENTLY ASKED QUESTIONS"
        subHeading=""
        faqData={faqList}
    /> */}
    <AboutIcons/>
    <ContactForm/>
</>
  )
}

export default ServiceInner