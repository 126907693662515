import React from 'react'
import { useNavigate } from 'react-router-dom'

function truncateText(text, maxLength) {
  if (text.length <= maxLength) {
    return text;
  } else {
    return text.substring(0, maxLength - 3) + '...';
  }
}

function BlogCard({
    catagory,
    date,
    content,
    img,
    anchor,
    redirectionFunction,
    title
}) {

  const shotendContent = truncateText(content,500)


  return (
    <div className='blog-card mb-5'>
      <h3 className='body-heading-b text-md-start text-center'>{title}</h3>
        <div className="blog_list_logo d-flex align-items-center justify-content-md-start justify-content-center">
          <img src="/images/icons/card-logo.png" className="blog_list_logo_img"/>
          <div className="blog_list_logo_text">
            <h6 className="blog_list_logo_title m-0">ACCOUNTAX</h6>
            <p className="blog_list_logo_date m-0">{date}  <span className='color-blue'>{catagory}</span></p>
          </div>
        </div>
        <img src={img} className='card-img my-3'/>
        <div className='body-paragraph text-md-start text-center' dangerouslySetInnerHTML={{__html:shotendContent}}></div>
        
        <p className='color-blue card-anchor mb-0 d-flex align-items-center justify-content-md-start justify-content-center' onClick={()=>{redirectionFunction(anchor)}}>CONTINUE READING</p>
    </div>
  )
}

export default BlogCard