import React from 'react'
import "./ServicesCarasoul.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import {Autoplay} from "swiper/modules"
import ServiceCard from './serviceCard';

const serviceData=[
    {
        icon:"/images/icons/service-accounting-icon.svg",
        heading:"Accounting",
        content:"Our experienced chartered accountants bring decades of expertise to manage all aspects of your accounting needs.",
        btnText:"Learn More",
        btnRef:"/accounting-solutions"
    },
    {
        icon:"/images/icons/service-payroll-icon.svg",
        heading:"Payroll Management",
        content:"Accountax International Inc. takes the burden off your shoulders, ensuring that your employees are paid accurately and on time.",
        btnText:"Learn More",
        btnRef:"/payroll-management"
    },
    {
        icon:"/images/icons/online-book-icon.svg",
        heading:"Bookkeeping",
        content:"We ensure that your financial records are not just accurate but readily available for your business's strategic decision-making.",
        btnText:"Learn More",
        btnRef:"/bookkeeping"
    },
    {
        icon:"/images/icons/service-business-financing-icon.svg",
        heading:"Business Financing Solutions",
        content:"At Accountax International Inc., we specialize in providing tailored business financing solutions designed to meet the unique needs of your enterprise.",
        btnText:"Learn More",
        btnRef:"/business-financing"
    },
    {
        icon:"/images/icons/service-business-registrations-icon.svg",
        heading:"Business Registrations",
        content:"Our expert team guides you through the intricacies of business registration, helping you make informed decisions that align with your goals.",
        btnText:"Learn More",
        btnRef:"/business-registration"
    },
    {
        icon:"/images/icons/service-corporate-administrative-icon.svg",
        heading:"Corporate Administrative Services",
        content:"Running a successful requires efficient corporate administration. Accountax International Inc. offers comprehensive corporate administrative services to streamline your operations.",
        btnText:"Learn More",
        btnRef:"/corporate-administrative-service"
    },
    {
        icon:"/images/icons/service-corporate-taxes-icon.svg",
        heading:"Corporate Taxes",
        content:"Accountax International Inc. brings decades of expertise to the table, ensuring that your corporate tax matters are managed with utmost proficiency.",
        btnText:"Learn More",
        btnRef:"/corporate-taxes"
    },
    {
        icon:"/images/icons/service-hst-returns-icon.svg",
        heading:"GST/HST Returns",
        content:"Our team specializes in managing your HST returns efficiently, ensuring compliance and identifying opportunities for savings within tax regulations.",
        btnText:"Learn More",
        btnRef:"/hst-returns"
    },
    {
        icon:"/images/icons/service-international-businesses-icon.svg",
        heading:"International Businesses",
        content:"With a deep understanding of both global and local businesses, we offer specialized services to ensure your international venture thrives in the Canadian market.",
        btnText:"Learn More",
        btnRef:"/international-business"
    },
    {
        icon:"/images/icons/service-personal-taxes-icon.svg",
        heading:"Personal Taxes",
        content:"Accountax International Inc. provides personalized tax planning services, ensuring that you optimize your financial position while staying compliant with tax laws.",
        btnText:"Learn More",
        btnRef:"/personal-tax"
    },
]



function ServicesCarasoul() {



    const handlePrevButtonClick = () => {
        const swiperInstance = document.querySelector('#service-cards-slider').swiper;
        swiperInstance.slidePrev();
      };
      
      const handleNextButtonClick = () => {
        const swiperInstance = document.querySelector('#service-cards-slider').swiper;
        swiperInstance.slideNext();
      };
  return (
    <div className='ServicesCarasoul-container standard-padding-space'>
        <div className='container mt-3'>

        
            <h3 className='main-heading body-heading-w '>We Share Solutions, <span className='heading-color-change'>Not Just Services!</span></h3>

        

                <Swiper
                    spaceBetween={60}
                    autoplay={{
                    delay: 1500,
                    disableOnInteraction: false,
                    }}
                    loop={true}
                    modules={[Autoplay]}
                    navigation={
                        {
                          nextEl: '.swiper-button-next',
                          prevEl: '.swiper-button-prev',
                        }
                        }
                    breakpoints={{
                        200:{
                        slidesPerView: 1,
                        },
                        1100: {
                        slidesPerView: 3,
                        }
                    }}
                    pagination={{ clickable: true }}
                    observer ={true}
                    observeParents={true}
                    parallax={true}
                    className=" my-5 px-3 px-md-5 py-4"
                    style={{position:"relative"}}
                    id="service-cards-slider"
                    >
                    
                    
                <div className="swiper-button-prev swiper-button d-lg-block d-none" onClick={handlePrevButtonClick}> 
                <img src="/images/icons/indus-carousel-arrow-left.png" className="arrow-img"/>
                </div>
                {
                        serviceData.map((ele,ind)=>{
                        return(<>
                            <SwiperSlide>
                                <ServiceCard
                                    icon={ele.icon}
                                    heading={ele.heading}
                                    content={ele.content}
                                    btnText={ele.btnText}
                                    btnRef={ele.btnRef}
                            
                                />
                            </SwiperSlide>
        
                            </>)
                        })
                    }
                    <div className="swiper-button-next swiper-button d-lg-block d-none" onClick={handleNextButtonClick}>
                    <img src="/images/icons/indus-carousel-arrow-right.png" className="arrow-img"/>
                    </div>
                </Swiper>
                </div>
    </div>

  )
}

export default ServicesCarasoul