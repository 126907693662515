import React from 'react'
import HeroBanner from '../components/Common Banner Component/HeroBanner'
import ContactForm from '../components/ContactForm/ContactForm'
import TextContent from '../components/TextContent/TextContent'

function Careers() {
    return (
        <>
            <HeroBanner
                Heading="CAREERS"
                subHeading=""
                spanHeading=""
                Paragraph=""
                btnText=""
                bgImg="/images/creative/careers-hero.jpg"
                darkBg={true}
                headingCenter={true}
                btn={false}
                socialLinks={false}
                serviceForm={false}
                
            />
             <div style={{height:"50px"}}></div>
            <TextContent 
                img={"/images/creative/career-text-1.png"} 
                heading={"Accountax Career Institute"} 
                shiftRow={true}
                subHeading={""} 
                content={"Welcome to the Accountax Career Institute, where we empower aspiring professionals with hands-on training in bookkeeping and accounting. Our institute is dedicated to providing comprehensive education, certifications, and placement services, ensuring that graduates are well-prepared to embark on successful careers in the dynamic field of accounting."}
                btn={true} 
                btnText="Let's Connect"
                btnRef='/contact'
                btnYellow={true}
            />
            <TextContent 
                img={"/images/creative/career-text-2.png"} 
                heading={"Hands On-Training in Bookkeeping and Accounting"} 
                shiftRow={false}
                subHeading={""} 
                content={"At Accountax Career Institute, we believe in equipping our students with practical skills that translate seamlessly into the professional world. Our hands-on training programs immerse students in the intricacies of bookkeeping and accounting, providing them with the practical knowledge and experience necessary for a successful career in the financial industry."}
                btn={true} 
                btnText="Let's Connect"
                btnRef='/contact'
                btnYellow={true}
            />
            <TextContent 
                img={"/images/creative/career-text-3.png"} 
                heading={"Certification"} 
                shiftRow={true}
                subHeading={""} 
                content={"Our certification programs are designed to validate your expertise and enhance your credibility in the job market. Earn a recognized certification upon successful completion of our courses, showcasing your commitment to excellence and readiness for a thriving career in accounting."}
                btn={true} 
                btnText="Let's Connect"
                btnRef='/contact'
                btnYellow={true}
            />
            <TextContent 
                img={"/images/creative/career-text-4.png"} 
                heading={"Placement Services"} 
                shiftRow={false}
                subHeading={""} 
                content={"Accountax Career Institute goes beyond education by providing comprehensive placement services. We leverage our network of industry connections to help our graduates secure meaningful employment opportunities upon completing their training. Our dedicated placement team works tirelessly to match graduates with positions that align with their skills and career goals."}
                btn={true} 
                btnText="Let's Connect"
                btnRef='/contact'
                btnYellow={true}
            />
            <TextContent 
                img={"/images/creative/career-text-5.png"} 
                heading={"Independent Accounting Practice for Graduates."} 
                shiftRow={true}
                subHeading={""} 
                content={"For those with an entrepreneurial spirit, our institute offers guidance on establishing an independent accounting practice. From understanding the legalities of starting your own firm to developing client relationships, we provide the necessary insights and resources to empower graduates to embark on their independent accounting journey."}
                btn={true} 
                btnText="Let's Connect"
                btnRef='/contact'
                btnYellow={true}
            />
    
            
           
            <ContactForm/>
        </>
      )
}

export default Careers