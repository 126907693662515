import React from 'react'
import HeroBanner from '../components/Common Banner Component/HeroBanner'
import PartnerCompanies from '../components/PartnerCompanies/PartnerCompanies'
import TextContent from '../components/TextContent/TextContent'
import CardNews from '../components/CardNews/CardNews'
import Testimonials from '../components/Testimonials/Testimonials'
import ContactForm from '../components/ContactForm/ContactForm'
import ServicesCarasoul from '../components/ServicesCarasoul/ServicesCarasoul'

function home() {
  return (
    <>
        <HeroBanner
            subHeading=""
            Heading="Accountax International"
            spanHeadingBlue=""
            Paragraph={`Benefit from the knowledge of Accountants with extensive training and 50+ years of experience. At Accountax International Inc., we understand that your financial success is the result of careful planning and strategic execution. We go beyond traditional accounting services, providing insights and strategies to empower your financial success.`}
            btnText="Get Started"
            bgImg="/images/creative/home-header-bg.jpg"
            btn={true}
            socialLinks={false}
            serviceForm={false}
            darkBg={true}
            customHeadingSize={`35px`}
            bgVideo="/images/Accountax-Video.mp4"
            bgEffect={true}
            headingCenter={false}
        />
        <PartnerCompanies/>
        <TextContent
            shiftRow={false}
            img="/images/creative/home-text.png"
            heading="ABOUT US"
            subHeading="Your Trusted Partner In Financial Planning"
            content="Accountax International Inc. has been a beacon of excellence in financial planning and accounting services for over 50 years now. We understand that your money, whether personal or business-related, deserves meticulous attention. Small errors in your financial records can have significant consequences, which is why entrusting your accounting needs to seasoned professionals is a smart choice."
            btn={true}
            btnText="Read More"
            btnRef="/about"
        />

        <ServicesCarasoul/>
        <CardNews/>
        <Testimonials/>
        <ContactForm/>
    </>
  )
}

export default home