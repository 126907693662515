import React from 'react'
import "./OurTeam.css"



const teamData = [
    {
        img:"/images/creative/team-mem-1.png",
        title:"JOHN DOE",
        content:'For over 50 years individuals and businesses have relied on Accountax International Inc.',
    },
    {
        img:"/images/creative/team-mem-2.png",
        title:"JOHN DOE",
        content:'For over 50 years individuals and businesses have relied on Accountax International Inc.',
    },
    {
        img:"/images/creative/team-mem-3.png",
        title:"JOHN DOE",
        content:'For over 50 years individuals and businesses have relied on Accountax International Inc.',
    },
    {
        img:"/images/creative/team-mem-4.png",
        title:"JOHN DOE",
        content:'For over 50 years individuals and businesses have relied on Accountax International Inc.',
    },
]


function TeamCard({
    img="",
    title="",
    content=''
})
{
    return(
        <div className='teamCard my-3'>
            <div className='d-flex flex-column align-items-center justify-content-center gap-2 text-center'>
                <img src={img} className='img-fluid card-img'/>
                <h5 className='title my-2'>{title}</h5>
                <p className='body-paragraph'>{content}</p>
            </div>
        </div>
    )
}


function OurTeam() {
  return (
    <div className='OurTeam-container standard-padding-space'>
        <div className='container'>
            <div className='text-content text-center px-3'>
                <h3 className='body-heading-b'>OUR <span>TEAM</span></h3>
                <p className='body-paragraph'>Our team of seasoned professionals is the heart of Accountax International Inc. Each member brings a wealth of experience and a commitment to excellence, ensuring that our clients receive the highest level of service.</p>
            </div>
        </div>
        
        <div className='container mt-5'>
            <div className='row gy-2'>
                {
                    teamData.map((ele)=>{
                       return( <div className='col-xl-3 col-md-6'>
                            <TeamCard
                                img={ele.img}
                                title={ele.title}
                                content={ele.content}
                            />
                        </div>)
                    })
                }
            </div>

        </div>

    </div>
  )
}

export default OurTeam