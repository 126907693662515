import React,{useState,useEffect} from "react";
import "./CardNews.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css';
import {Autoplay} from "swiper/modules"
import CardBox from "./CardBox";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Spinner } from "react-bootstrap";


const newsData = [
  {
    cardImg:"./images/creative/news-card-img.jpg", 
    title:"The ABCs of Efficient Bookkeeping for Small Businesses" ,
    content:"Whether you&#39;re a startup or an established small business, this blog provides actionable insights to maintain..."
  },
  {
    cardImg:"./images/creative/news-card-img.jpg", 
    title:"Tax Planning Strategies for Sustainable Corporate Growth" ,
    content:"Our blog post takes you through the nuances of strategic tax planning, offering insights on optimizing your corpor..."
  },
  {
    cardImg:"./images/creative/news-card-img.jpg", 
    title:"International Business Expansion: Key Considerations for..." ,
    content:"Thinking of expanding your international business into Canada? Our blog provides a comprehensive guide on key consideratio..."
  },
 

  {
    cardImg:"./images/creative/news-card-img.jpg", 
    title:"The ABCs of Efficient Bookkeeping for Small Businesses" ,
    content:"Whether you&#39;re a startup or an established small business, this blog provides actionable insights to maintain..."
  },
  {
    cardImg:"./images/creative/news-card-img.jpg", 
    title:"Tax Planning Strategies for Sustainable Corporate Growth" ,
    content:"Our blog post takes you through the nuances of strategic tax planning, offering insights on optimizing your corpor..."
  },
  {
    cardImg:"./images/creative/news-card-img.jpg", 
    title:"International Business Expansion: Key Considerations for..." ,
    content:"Thinking of expanding your international business into Canada? Our blog provides a comprehensive guide on key consideratio..."
  },
]

let defaultBlogObj;

function CardNews() {
  const [blogs, setBlogs] = useState([]);
  const [recentBlogs, setRecentBlogs] = useState([]);

  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  let blogAPICalled = false;

  useEffect(() => {
    getAllBlogsListing();
  }, []);

  const getAllBlogsListing = () => {
    if (blogAPICalled) return;
    blogAPICalled = true;

    setLoading(true);
    axios
      .get("/blogs", {})
      .then((res) => {
        if (res.data.status === "success") {
          let Updated_data_Blogs = [];
          let Updated_recent_blogs = [];
          res.data.data.forEach((item) => {
            Updated_data_Blogs.push({
              id: item.blog_id,
              main_heading: item.title,
              paragraph: item.brief_paragraph,
              logo: "/favicon.png",
              entity: "Venus",
              date: item.published_date,
              slug_url: item.slug_url,
              category: item.category,
              blog_creative: item.blog_image,
            });
          });
          res.data.data.forEach((item) => {
            Updated_recent_blogs.push({
              id: item.blog_id,
              slug_url: item.slug_url,
              logo: item.blog_image,
              blog_description: item.title,
              date: item.published_date,
            });
          });
          setRecentBlogs(Updated_recent_blogs.slice(0, 3));
          setBlogs(Updated_data_Blogs);
          if(blogs.length <= 3)
          {
            setBlogs((prev)=>[...prev,...prev])
          }
          defaultBlogObj = Updated_data_Blogs;
          setLoading(false);
        }
          
      })
      .catch((err) => {
        console.log("eror =>",err);
        setLoading(false);
      });
  };

  const convertToSlug = (str) => {
    return str.toLowerCase().replace(/\s+/g, "-");
  };

  const redirectUserToBlog = (id) => {
    if (!id) return;
    let slug = convertToSlug(id);

    navigate(`/news/${slug}`);
  };


  return (
    <div className="news-container standard-padding-space">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="news-upper-content">
              <div className="text-content">
                <h3 className="body-heading-b">Blogs: <span>Stay Informed</span></h3>
                <p className="content body-paragraph">
                Welcome to the Accountax Blogs – your go-to resource for financial updates, and expert advice on financial planning, business management, and international operations. Our blog is designed to keep you informed, inspired, and ahead of the curve in financial world.
                </p>
              </div>
            </div>
          </div>
        </div>

        {loading ? 
          (<div className="w-100 text-center mb-5">
            <Spinner
              style={{ color: "#85329A", width: "120px", height: "120px" }}
            />
          </div>):
          (
          <Swiper
            spaceBetween={40}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
            loop={true}
            // modules={[Autoplay]}
            breakpoints={{
              280: {
                slidesPerView: 1,
              },
              992: {
                slidesPerView: 3,
              },
            }}
            className="p-3"
          >
            {blogs.map((data, ind) => {
              return (
                <>
                  <SwiperSlide key={ind}>
                    <CardBox
                      cardImg={data.blog_creative} 
                      title={data.main_heading} 
                      content={data.paragraph} 
                      anchor={data.slug_url}
                      redirectionFunction={redirectUserToBlog}
                      publishDate={data.date}
                    />
                  </SwiperSlide>
                </>
              );
            })}
          </Swiper>
          )}
          <div className="col-md-12">
            <div className="blog-btn d-flex align-items-center justify-content-center mt-5">
              <button className="genral-btn" onClick={()=>{navigate("/blog")}}>See All</button>
            </div>
          </div>
      </div>
    </div>
  );
}

export default CardNews