import React from 'react'
import DropDownBox from "./DropDownBox"
import "./FaqSection.css"

function FaqSection({  
    heading,
    subHeading,
    showImg=false,
    faqData=[]
}) 
{
    

  return (
    <div className='faq-container standard-padding-space mt-5'>
        <div className='container'>
        <div className='text-content d-flex flex-column align-items-center w-75 mx-auto'>
            <h3 className='body-heading-b'>{heading}</h3>
            <h6 className='sub-heading' style={{color : "#F2CC52"}}>{subHeading}</h6>
            
        </div>
        <div className='dropdown-container mt-4'>
            {
                faqData.map((ele,ind)=>{
                    return(
                        <>
                            <DropDownBox title={ele.title} content={ele.content} />
                            {ind<(faqData.length-1) && <div className='line-seprator'></div>}
                        </>
                    )
                })
            }
        </div>
        </div>
    </div>
  )
}

export default FaqSection