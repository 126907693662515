import React from 'react'
import HeroBanner from '../components/Common Banner Component/HeroBanner'
import Testimonials from '../components/Testimonials/Testimonials'
import ContactForm from '../components/ContactForm/ContactForm'
import TextContent from '../components/TextContent/TextContent'
import AboutText from "../components/AboutText/AboutText"
import OurTeam from '../components/OurTeam/OurTeam'

function Contact() {
    return (
        <>
            <HeroBanner
                subHeading=""
                preSpanHeading="Need help with numbers?"
                spanHeading=" Let’s talk!"
                Paragraph="Have questions or ready to take the next step in optimizing your financial success? The team at Accountax International Inc. is here to assist you."
                btnText=""
                serviceForm={true}
                bgImg="/images/creative/contact-hero.jpg"
                darkBg={true}
                headingCenter={false}
                btn={false}
                socialLinks={true}
                customHeadingSize={"40px"}
            />
            <div className='container p-0 map-contact-container' >

                <img src='/images/creative/location-map.png' className='img-fluid'/>

            </div>
        </>
    )
}

export default Contact