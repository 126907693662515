import { useNavigate } from "react-router-dom"

function CardBox(props) {
  return (
    <div className="card-box" onClick={()=>{props.redirectionFunction(props.anchor)}}>
        <img src={props.cardImg} alt="someImage" />
        <div className="card-text-content">
          <h5>{props.title}</h5>
        </div>
        <div className="line-seprator"></div>
        <div className="blog-details d-flex align-items-center justify-content-between">
          <p>Published By: {props.author}</p>
          <p>{props.publishDate}</p>

        </div>
    </div>
  )
}

export default CardBox