import React from 'react'
import "./ScrollingTextContent.css"
import { useNavigate } from 'react-router-dom'

function TextContent({
    bgColor,
    shiftRow=false,
    heading="",
    subHeading="",
    content="",
    img,
    btn=false,
    btnText="Read More",
    btnRef="/",
    btnYellow=false

}) {
    const paragraphs = content.split('\n').map((paragraph, index) => <p key={index} className='content body-paragraph mb-3'>{paragraph}</p>);
    const navigate = useNavigate()
  return (
    <div className='ScrollingTextContent-container standard-padding-space mb-4' style={{backgroundColor: bgColor}}>
        <div className='container'>
            <div className={`row d-flex ${ shiftRow ? "flex-row-reverse" : ""} align-items-center justify-content-center gy-4`}>
                <div className='col-md-6 h-100 my-auto px-4'>
                    <img src={ img} className='img-fluid' />
                </div>
                <div className='col-md-6'>
                    <div className='text-content d-flex flex-column align-items-start justify-content-center gap-lg-2 h-100 px-2 px-md-0'>
                        {subHeading == "" ? <></> :<h5 className='sub-title'>{ subHeading}</h5>}
                        <h3 className='heading body-heading-b'>{ heading}</h3>
                        <div className='scroll-content'>
                            <p className='content body-paragraph'>Since establishing the Accounting Firm of <b>ACCOUNTAX INTERNATIONAL INC.</b> during 1974, more than four decades ago, Vijay Kapur has continuously shown his devotion to giving his clients exceptional services. Several high net-worth individuals and organizations and small to medium sized enterprises have benefited from his excellent guidance through difficult accounting and tax concerns, which has allowed them to minimize obligations and maximize their tax positions.</p>
                            <p className='content body-paragraph'>Vijay Kapur is a highly accomplished and experienced Senior Tax Accountant and Consultant, with an extensive background in Accounting, Taxation and Financial Management, Vijay Kapur brings a wealth of experience to his role. His exceptional analytical skills, deep knowledge of accounting and tax laws, and meticulous attention to detail have made him a trusted advisor for both individuals and businesses.</p>
                            <p className='content body-paragraph'>In his capacity as a senior Tax consultant, Vijay Kapur is in-charge of managing a group of tax professionals, supervising accounting, tax planning, business consulting, and compliance tasks and ensuring adherence to regulatory guidelines. He actively stays updated with the latest industry requirements and trends to provide accurate and up-to-date advice to clients.</p>

                            <h5 className='sub-title'>EDUCATION, TRAINING AND CERTIFICATIONS:</h5>
                            <p className='content body-paragraph'>With a strong foundation in accounting and taxation, Vijay Kapur graduated with a B. Com from a renowned University, where he gained a solid foundation in financial principles of accounting and taxation. Completed his article ship during Chartered Accountants career. Along with being a fellow member of the I.C.I.A, he also possesses the following certificates: F.I.C.I.A., A.I.P.F.M., D.M.M., A.P.A, R.P.A and many more memberships.</p>

                            <h5 className='sub-title'>Professional Experience:</h5>
                            <p className='content body-paragraph'>During his career as an accountant, he has consistently demonstrated his dedication and commitment to providing outstanding service to his clients. He has successfully guided numerous high-net-worth individuals, corporations, and small businesses through complex accounting and tax matters, helping them optimize their tax positions and minimize liabilities.</p>
                            <p className='content body-paragraph'>Vijay Kapur has developed a <b>FRANCHISE system</b> in accounting during 1981 and have opened many offices around in Canada. He has also registered a trademark of <b>ACCOUNTAX & Design</b>.</p>
                            <p className='content body-paragraph'>Vijay Kapur has a one stop service facility at his present location in Niagara Falls, Ontario, CANADA. The Group includes Mortgage Brokers, Real Estate Agent, Lawyers, Travel professionals, Computer Specialists and Telephone Services, Importers and Exporters office of Surgical Equipment and many more are still growing.</p>
                        </div>
                        {/* <p className='content body-paragraph'>{ paragraphs}</p> */}
                        {btn && <button className={`genral-btn ${btnYellow && "btn-y"} mt-2`} onClick={()=>{navigate(btnRef)}}>{btnText}</button>}
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default TextContent