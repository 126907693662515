import React from 'react'
import { useNavigate } from 'react-router-dom'


function ServiceCard(props) {
  const navigate = useNavigate()
  return (
    <div className=' col-lg-4 col-md-6 text-center'>
      <div className='service-card text-center'>
        <div className='img-round-container'>
            <img src={props.img} className='icon-img'/>
        </div>
        <h5 className='card-title mt-2'>{props.title}</h5>
        <p className='body-paragraph mb-0'>{props.content}</p>
        <button className='genral-btn mt-3' onClick={()=>{navigate(props.anchor)}}>Learn More</button>
        </div>
    </div>
  )
}

export default ServiceCard