import React from 'react'
import "./MostCommonBlog.css"
import CardBox from "./CardBox";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { useNavigate } from 'react-router-dom';

const commonBlogData = [
  {
    cardImg: "./images/creative/common-blog-img.jpg",
    title: "The ABCs of Efficient Bookkeeping for Small Businesses",
    author: "Accountax",
    publishDate: "Apr 20, 2023",
  },
  {
    cardImg: "./images/creative/common-blog-img.jpg",
    title: "Tax Planning Strategies for Sustainable Corporate Growth",
    author: "Accountax",
    publishDate: "Jun 10, 2023",
  },
  {
    cardImg: "./images/creative/common-blog-img.jpg",
    title: "International Business Expansion: Key Considerations for...",
    author: "Accountax",
    publishDate: "Apr 10, 2023",
  },
  {
    cardImg: "./images/creative/common-blog-img.jpg",
    title: "The ABCs of Efficient Bookkeeping for Small Businesses",
    author: "Accountax",
    publishDate: "Apr 10, 2023",
  },
  {
    cardImg: "./images/creative/common-blog-img.jpg",
    title: "Tax Planning Strategies for Sustainable Corporate Growth",
    author: "Accountax",
    publishDate: "Apr 10, 2023",
  },
  {
    cardImg: "./images/creative/common-blog-img.jpg",
    title: "International Business Expansion: Key Considerations for...",
    author: "Accountax",
    publishDate: "Apr 10, 2023",
  },
  
];

function MostCommonBlog({recentBlogs}) {
  const navigate = useNavigate()

    const convertToSlug = (str) => {
        return str.toLowerCase().replace(/\s+/g, "-");
    };

    const redirectUserToBlog = (id) => {
        if (!id) return;
        let slug = convertToSlug(id);

        navigate(`/news/${slug}`);
    };
  return (
    <div className='common-blog-container standard-padding-space'>
        <div className='container'>

        <h3 className='body-heading-b text-center mb-5'>Most popular Blogs</h3>

        <Swiper
            spaceBetween={40}
            breakpoints={{
                280: {
                  slidesPerView: 1,
                },
                992: {
                  slidesPerView: 3,
                }
              }}
            className="card-list p-4 px-2"
            >
            {recentBlogs?.map((blog,ind)=>{
              return (
                <>
                  <SwiperSlide key={ind}>
                    <CardBox
                      author={"Accountax"}
                      cardImg={blog.logo}
                      title={blog.blog_description}
                      publishDate={blog.date}
                      anchor={blog.slug_url}
                      redirectionFunction={redirectUserToBlog}
                    />
                  </SwiperSlide>
                </>
              );
            })}
            
        </Swiper>
        </div>
    </div>
  )
}

export default MostCommonBlog