import React from 'react'
import HeroBanner from '../components/Common Banner Component/HeroBanner'
import Testimonials from '../components/Testimonials/Testimonials'
import ContactForm from '../components/ContactForm/ContactForm'
import TextContent from '../components/TextContent/TextContent'
import AboutText from "../components/AboutText/AboutText"
import OurTeam from '../components/OurTeam/OurTeam'

import ScrollingTextContent from "../components/ScrollingTextContent/ScrollingTextContent"
import ImageSlideContent from '../components/ImageSlideContent/ImageSlideContent'

const certificateImages = [
    "/images/creative/certificate-image-1.webp",
    "/images/creative/certificate-image-2.webp",
    "/images/creative/certificate-image-3.webp",
    "/images/creative/certificate-image-4.webp",
    "/images/creative/certificate-image-5.webp",
    "/images/creative/certificate-image-6.webp",
    "/images/creative/certificate-image-7.webp",
    "/images/creative/certificate-image-8.webp",
    "/images/creative/certificate-image-9.webp",
    "/images/creative/certificate-image-10.webp",
    "/images/creative/certificate-image-11.webp",
    "/images/creative/certificate-image-12.webp",
 ]

function about() {
  return (
    <>
        <HeroBanner
            Heading="ABOUT US"
            subHeading=""
            spanHeading=""
            Paragraph=""
            btnText=""
            bgImg="/images/creative/about-hero.jpg"
            darkBg={true}
            headingCenter={true}
            btn={false}
            socialLinks={false}
            serviceForm={false}
            
        />
        <div style={{height:"50px"}}></div>
        <TextContent 
            img={"/images/creative/about-content-1.png"} 
            heading={"We are Accountax"} 
            shiftRow={true}
            subHeading={"Your Trusted Partner In Financial Planning"} 
            btn={false} 
            content={"We are not just financial service providers; we are partners invested in your success. Our team comprises chartered accountants with extensive training, bringing fifty years of collective expertise to the table. We have been a pillar of support for businesses and individuals alike, steering through the complexities of financial planning with precision.\n Our client base spans a diverse spectrum, from dynamic startups and small businesses to well-established corporations and high net-worth individuals. We take a proactive and responsive approach, ensuring that our clients feel supported and confident in every financial decision they make."}
        />
        <ScrollingTextContent 
            img={"/images/creative/about-content-1.png"} 
            heading={"ACCOUNTAX INTERNATIONAL INC."} 
            shiftRow={false}
            subHeading={"Profile Summary of Vijay Kapur of:"} 
            btn={false} 
            content={""}
        />

        <ImageSlideContent
             img={certificateImages}
             subHeading={"Certifications "} 
             heading={"ACCOUNTAX FINANCIAL "} 
             shiftRow={true}
             bgColor={"#F9F8F8"}
             btn={false} 
             content={"For over 50 years individuals and businesses have relied on Accountax International Inc. in Niagara Falls for all of their accounting needs. When it comes to you or your business's money, you want to make sure that its in good hands. Even small errors in your books can add up, and that's why its in your best interest to have a professional take care of your accounting. "} 
        />

        <AboutText/>

    <OurTeam/>
       
        <Testimonials/>
        <ContactForm/>
        <div className='container standard-padding-space'>
            {/* <iframe className='contact-map' src="https://maps.google.com/maps?q=True%20Life%20Wellness%20and%20Physiotherapy%2C%20North%20York&amp;t=m&amp;z=10&amp;output=embed&amp;iwloc=near" title="True Life Wellness and Physiotherapy, North York" aria-label="True Life Wellness and Physiotherapy, North York"></iframe> */}
            <iframe className='contact-map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2913.0527704965416!2d-79.11310322530473!3d43.103405887818376!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89d34507e5715379%3A0x65d8a83199a933b3!2sAccountax%20international!5e0!3m2!1sen!2s!4v1717006720323!5m2!1sen!2s"></iframe>
        </div>
    </>
  )
}

export default about