import React from 'react'
import HeroBanner from '../components/Common Banner Component/HeroBanner'
import Testimonials from '../components/Testimonials/Testimonials'
import ContactForm from '../components/ContactForm/ContactForm'
import OnlineServices from '../components/OnlineServices/OnlineServices'
import WorkIndustry from '../components/WorkIndustry/WorkIndustry'
import AboutIconsHero from '../components/AboutIconsHero/AboutIconsHero'

function service() {
  return (
    <>
        <HeroBanner
            Heading="OUR SERVICES"
            subHeading=""
            spanHeading=""
            Paragraph=""
            btnText=""
            bgImg="/images/creative/service-hero-bg.jpg"
            darkBg={true}
            headingCenter={true}
            btn={false}
            socialLinks={false}
            serviceForm={false}
            
        />
        <AboutIconsHero/>
        <OnlineServices/>
        <WorkIndustry/>
        <Testimonials/>
        <ContactForm/>
    </>
  )
}

export default service