import "./PartnerCompanies.css"
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import {Autoplay} from "swiper/modules"

function PartnerCompanies() {
  return (
    <div className='partnerCompanies_container container standard-margin-space'>
        <Swiper
            spaceBetween={20}
            autoplay={{
              delay: 1500,
              disableOnInteraction: false,
              loopFillGroupWithBlank: true,
            }}
            loop={true}
            modules={[Autoplay]}
            breakpoints={{
                200:{
                  slidesPerView: 1,
                },
                550: {
                  slidesPerView: 2,
                },
                800: {
                  slidesPerView: 3,
                },
                1200: {
                  slidesPerView: 4,
                }
              }}
            className="companies-logo-box px-1"
          >
             <SwiperSlide >
              <img src="images/icons/partner-logo-1.png" alt="" />
             </SwiperSlide>
             <SwiperSlide >
              <img src="images/icons/partner-logo-2.png" alt="" />
             </SwiperSlide>
             <SwiperSlide >
              <img src="images/icons/partner-logo-3.png" alt="" />
             </SwiperSlide>
             <SwiperSlide >
              <img src="images/icons/partner-logo-4.png" alt="" />
             </SwiperSlide>
             <SwiperSlide >
              <img src="images/icons/partner-logo-5.png" alt="" />
             </SwiperSlide>
             
             <SwiperSlide >
              <img src="images/icons/partner-logo-1.png" alt="" />
             </SwiperSlide>
             <SwiperSlide >
              <img src="images/icons/partner-logo-2.png" alt="" />
             </SwiperSlide>
             <SwiperSlide >
              <img src="images/icons/partner-logo-3.png" alt="" />
             </SwiperSlide>
             <SwiperSlide >
              <img src="images/icons/partner-logo-4.png" alt="" />
             </SwiperSlide>
             <SwiperSlide >
              <img src="images/icons/partner-logo-5.png" alt="" />
             </SwiperSlide>
             
        </Swiper>
    </div>
  )
}

export default PartnerCompanies