import React from 'react'
import "./AboutText.css"

function AboutText() {
  return (
    <div className='about-text-container standard-padding-space'>
        <div className='container mb-3'>
            <div className='row gy-4'>
                <div className='col-lg-4'>

                <div className=' card-container m-auto py-4 px-2 col-md-6 text-center d-flex flex-column align-items-center gap-4'>
                    
                        <img src='/images/icons/text-icon-1.svg' className='about-icon'/>
                        <h3 className='color-yellow m-0'>OUR MISSION</h3>
                        <p className='body-paragraph m-0'>Our mission is clear – to empower businesses and individuals with the knowledge and tools needed for financial triumph. We strive to provide tailored financial solutions, guiding our clients towards sustainable growth and prosperity.</p>
                    
                </div>
                </div>
                <div className='col-lg-4'>

                <div className=' card-container m-auto py-4 px-2 col-md-6 text-center d-flex flex-column align-items-center gap-4'>
                    
                <img src='/images/icons/text-icon-2.svg' className='about-icon'/>
                    <h3 className='color-yellow m-0'>OUR VISION</h3>
                    <p className='body-paragraph m-0'>Our vision is to be more than just an accounting firm; we aspire to be your enduring partner in success.Through strategic guidance, we aim to contribute to your long-term success.</p>
                    
                </div>
                </div>
                <div className='col-lg-4'>

                <div className=' card-container m-auto py-4 px-2 col-md-6 text-center d-flex flex-column align-items-center gap-4'>
                    
                <img src='/images/icons/text-icon-3.svg' className='about-icon'/>
                    <h3 className='color-yellow m-0'>OUR VALUES</h3>
                    <p className='body-paragraph m-0'>Our core values form the foundation of everything we do. Upholding the highest ethical standards is non-negotiable. We believe in transparency, honesty, and accountability in all our interactions.</p>
                    
                </div>
                </div>
                
            </div>
        </div>
    </div>
  )
}

export default AboutText