import React from 'react'
import "./ServicesInnerTextContent.css"

function ServicesInnerTextContent({
    heading="",
    thumbnailImg="",
    emphasisText=null,
    content="",
    whatIntro=null,
    whatList=null
}) {
    const paragraphs = content.split('\n').map((paragraph, index) => <p key={index} className='body-paragraph my-3'>{paragraph}</p>);
  return (
    <div className='ServicesInnerTextContent'>
        <div className='text-content standard-margin-space'>
            <div className='container'>
                <img src={thumbnailImg} className='img-fluid w-100 text-img-thumb'/>
                
                <h3 className='body-heading-b mt-5'>{heading}</h3>
                <p>{paragraphs}</p>

                {emphasisText &&
                <div className='emphasis-text-container mt-5'>
                    <p className='body-paragraph py-4'>{emphasisText}</p>
                </div>
                }

                {
                    whatIntro &&
                <div className='what-list mt-5'>
                    <h3 className='body-heading-b'>WHAT WE DO</h3>
                    <p className='body-paragraph my-3'>{whatIntro}</p>

                    {whatList &&
                        whatList.map((ele)=>{
                            return(
                                <div className='d-flex align-items-center gap-3 my-3'>
                                    <img src='/images/icons/check-mark-bullet.svg' style={{height:"15px",width:"15px"}}/>
                                    <p className='body-paragraph mb-0'>{ele}</p>
                                </div>
                            )
                        })
                    }
                </div>
                }
            </div>
        </div>
    </div>
  )
}

export default ServicesInnerTextContent