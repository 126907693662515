import './App.css';
import "./bootstrap.min.css"
import { Routes, Route ,Navigate} from 'react-router-dom';
import Home from "./screens/home"
import About from "./screens/about"
import Service from './screens/service';
import BlogMain from './screens/blogMain';
import BlogInner from './screens/blogInner';
import Footer from "./components/Footer/Footer"
import ServiceInner from './screens/serviceInner';
import Careers from './screens/careers';
import Contact from './screens/contact';
import NotFound from './screens/NotFound';



import LoginScreen from "./AdminScreens/LoginScreen";
import BlogsScreen from "./AdminScreens/BlogsScreens/BlogsScreen";
import AdminScreen from "./AdminScreens/AdminScreen";
import LocationScreen from './screens/LocationScreens/LocationScreen';
import LocationPages from "./components/LocationPages_/LocationPages"


const individualServices = [
  {
    bannerImg:"/images/creative/Book-Keeping-Banner.webp",
    thumbnailImg:"/images/creative/Book-Keeping-2.webp",
    ServicesInnerTextContent:{
      heading:"Reliable Bookkeeping Services",
      content:"Efficient bookkeeping is the cornerstone of financial success for any business. At Accountax, we offer more than just routine bookkeeping – we provide comprehensive solutions that elevate your financial management. Our expert team ensures your financial records are not just accurate but a strategic asset for your business. From organizing transactions to creating comprehensive financial reports, we streamline the entire bookkeeping process. With a commitment to precision, our services go beyond routine entries, offering insights that drive informed decision-making. Whether you're a startup, small business, or a growing enterprise, our tailored bookkeeping solutions cater to your unique needs. Trust Accountax to not only keep your financial books in order but to transform them into a catalyst for your business's enduring success.",
      emphasisText:"“Bookkeeping is the recording of financial transactions, and is part of the process of accounting in business and other organizations. It involves preparing source documents for all transactions, operations, and other events of a business.",
      whatIntro:"Partner with Accountax to access bookkeeping services that go beyond the standard, guaranteeing precision, effectiveness, and strategic financial insights tailored to your business.",
      whatList:[
        "Our experienced team at Accountax is dedicated to maintaining accurate and up-to-date financial records.",
        "We go beyond traditional bookkeeping by employing modern technologies and streamlined processes to enhances accuracy",
        "Whether you prefer manual or computerized systems, we adapt our approach to align with your business model.",
        "Our detailed reports and analyses empower you to make informed decisions and navigate your business towards greater financial success.",
        "Accountax ensures that your books adhere to the latest accounting standards and regulatory requirements, providing you with peace of mind.",
      ]
    },
    faq:null,
    serviceName:"BookKeeping",
    slug:"/bookkeeping"
  },
  {
    bannerImg:"/images/creative/Payroll-Project-banner.webp",
    thumbnailImg:"/images/creative/Payroll-2.webp",
    ServicesInnerTextContent:{
      heading:"Efficient Payroll Management",
      content:"Efficient payroll management is crucial for organizations to maintain financial transparency, compliance, and employee satisfaction. It involves leveraging technology, staying updated on regulatory changes, and implementing best practices to streamline processes and reduce errors. Outsourcing payroll services to specialized firms, like Accountax, can also be a strategic approach for businesses seeking professional expertise and efficient payroll administration.\n Effective payroll management is integral to the smooth functioning of any business, and at Accountax, we redefine it as a strategic advantage for your organization. Our comprehensive payroll services go beyond the routine, ensuring accuracy, compliance, and efficiency in every payroll cycle, empowering you to focus on core business objectives. Beyond routine payroll tasks, we also help businesses with tax compliance, benefits administration, and recordkeeping.",
      emphasisText:"“Payroll management refers to the systematic administration of an organization's financial records related to employee compensation and benefits. It encompasses a range of tasks, including calculating and distributing salaries, withholding taxes, managing benefits, and ensuring compliance with relevant employment laws and regulations.",
      whatIntro:"Let us streamline your payroll processes with accuracy, compliance, and efficiency, allowing you to focus on what matters most – the success of your business.",
      whatList:[
        "Our services are customized to align with your organization's specific requirements, providing a personalized and efficient payroll solution.",
        "Our technology-driven approach ensures that your payroll processes are efficient, secure, and up-to-date.",
        "Whether you require traditional payroll processing or prefer the convenience of automated systems, we adapt.",
        "Beyond basic payroll processing, we provide comprehensive reports and analyses. These insights empower you with better financial decision-making.",
        "Accountax stays informed about the latest labor laws and tax regulations, ensuring that your payroll processes adhere to all legal requirements.",
      ]
    },
    faq:null,
    serviceName:"Payroll Management",
    slug:"/payroll-management"
  },
  {
    bannerImg:"/images/creative/Accounting-Banner.webp",
    thumbnailImg:"/images/creative/Accounting-2.webp",
    ServicesInnerTextContent:{
      heading:"Strategic Accounting Solutions",
      content:"Effective accounting is not just about numbers; it's about empowering your organization with strategic financial insights. At Accountax, we redefine accounting as a catalyst for your success. Our seasoned team of experts goes beyond traditional practices, offering comprehensive accounting solutions that transcend routine entries. We approach accounting as a holistic endeavor, integrating financial data to provide a comprehensive view of your organization's health and performance. Recognizing the unique nature of each business, our accounting services are tailored to suit your specific industry, size, and operational nuances. Beyond number crunching, our detailed financial reports and analyses empower you to make informed, strategic decisions for the future of your organization. We navigate the complex landscape of accounting regulations, ensuring your books adhere to the latest standards and legal requirements.",
      emphasisText:"“Accounting firms primarily engage in core activities such as accounting and auditing services. However, they often extend their offerings to additional services such as merger audits, insolvency services, tax advice, investment services, and management consulting.",
      whatIntro:"Let our expertise unleash the financial insight your organization needs to thrive and prosper.",
      whatList:[
        "Our accounting services integrate all aspects of your financial data, providing a complete overview to guide strategic decision-making.",
        "Recognizing the uniqueness of each business, our accounting services are customized to align with your industry, size, and specific operational needs.",
        "Detailed financial reports and analyses empower you with insights to make informed, strategic decisions that drive the future success of your organization.",
        "Our team ensures that your books adhere to the latest accounting standards and legal requirements, providing peace of mind and minimizing risk.",
        
      ]
    },
    faq:null,
    serviceName:"Accounting Solutions",
    slug:"/accounting-solutions"
  },
  {
    bannerImg:"/images/creative/Personal-Taxes-Banner.webp",
    thumbnailImg:"/images/creative/Personal-Taxes-2.webp",
    ServicesInnerTextContent:{
      heading:"Personal Tax Services",
      content:"At Accountax, we understand that personal finances require meticulous attention. Our Personal Tax Services are designed to navigate the complexities of your financial landscape, ensuring that you maximize your returns while staying compliant with tax regulations.\nBenefit from the expertise of our seasoned tax consultants who stay abreast of the latest tax laws and changes, providing you with strategic guidance for optimal tax planning. mYour financial situation is unique, and so are our solutions. We tailor our personal tax services to your specific circumstances, ensuring that you take advantage of all available deductions and credits. Streamline the tax filing process with our efficient services. We ensure that your personal tax returns are accurately prepared, minimizing the risk of errors and maximizing your potential refunds.",
      emphasisText:"Working with expert personal tax advisors and preparation services ensures full tax compliance, enabling you to capitalize on available tax credits. Reduce the risk of errors or missed deadlines, and secure the tax return rightfully due to you.",
      whatIntro:"Choose Accountax for Personal Tax Services that go beyond routine filings, offering you personalized guidance and strategic planning to optimize your financial outcomes.",
      whatList:[
        "Our experts analyze your income, deductions, and credits to optimize your tax position, ensuring you keep more of your hard-earned money.",
        "We handle all the necessary paperwork, ensuring accurate and timely submission of your personal tax returns while minimizing the risk of errors.",
        "Leveraging our in-depth knowledge of tax laws, we identify opportunities to maximize deductions and credits available to you.",
        "By keeping your personal tax matters in order, we mitigate the risk of audits and penalties, providing you with peace of mind.",
        "Whether you're planning for the future or need assistance with unexpected changes, our team is here to provide ongoing guidance and support.",
        
      ]
    },
    faq:null,
    serviceName:"Personal Taxes",
    slug:"/personal-tax"
  },
  {
    bannerImg:"/images/creative/HST-Returns-Banner.webp",
    thumbnailImg:"/images/creative/HST-Returns-2.webp",
    ServicesInnerTextContent:{
      heading:"GST/HST Returns Services",
      content:"Accurate and timely filing of HST returns is essential for businesses engaged in taxable activities. Adhering to HST regulations is a legal requirement for businesses in Canada. Failure to comply can result in penalties and legal complications. Properly managing HST returns ensures a clear understanding of your financial standing. It helps in budgeting, forecasting, and maintaining healthy cash flow. Accurate and timely HST returns contribute to the credibility of your business. This is especially crucial when dealing with clients, partners, and financial institutions.\n We understand the importance of meeting deadlines. We ensure your HST returns are accurate and comply with the latest legislative updates. Our goal is to help you minimize tax liabilities while maximizing available benefits. Trust us to navigate the complex world of Canadian taxation, providing you with reliable, efficient, and expert accounting and bookkeeping services.",
      emphasisText:"HST Returns refer to the reporting and remittance of the Harmonized Sales Tax, a consumption tax in Canada. This tax amalgamates the federal Goods and Services Tax (GST) and provincial sales taxes, simplifying the taxation process for businesses.",
      whatIntro:"Partnering with Accountax International for your HST returns ensures peace of mind, allowing you to focus on growing your business.",
      whatList:[
        "Our seasoned professionals possess extensive knowledge of Canadian tax laws and regulations, including the nuances of HST.",
        "Accountax leverages cutting-edge technology to streamline the HST return process. This reduces the administrative burden and also minimizes errors.",
        "Beyond simple compliance, we provide strategic advice on optimizing your tax position.",
        "Accountax is committed to ensuring your HST returns are filed punctually, preventing any unnecessary penalties.",    
      ]
    },
    faq:null,
    serviceName:"GST/HST Returns",
    slug:"/hst-returns"
  },
  {
    bannerImg:"/images/creative/Business-Financing-Banner.webp",
    thumbnailImg:"/images/creative/Business-Financing-2.webp",
    ServicesInnerTextContent:{
      heading:"Business Financing Solutions",
      content:"Accountax International is committed to being your partner in success, offering tailored Business Financing Solutions that empower your business to reach new heights. Whether you're a startup, SME, or established enterprise, trust us to provide the financial expertise and support you need for sustained growth and prosperity. Adequate financing is essential for business expansion, launching new products, entering new markets, or investing in technology. It fuels your growth ambitions. Well-structured financing provides stability, enabling you to weather economic downturns and unforeseen challenges without compromising your day-to-day operations. Access to financing opens doors to strategic opportunities. Whether it's acquiring a competitor, investing in research and development, or diversifying your offerings, the right financing can make it possible. Our comprehensive suite of services is designed to provide customized financing solutions, ensuring that your business not only survives but thrives in today's dynamic economy.",
      emphasisText:"Business Financing Solutions encompass a range of strategies and services aimed at securing the necessary capital for your business operations, expansions, or strategic initiatives. These solutions can include loans, lines of credit, venture capital, and other financial instruments.",
      whatIntro:"Accountax International stands as your trusted partner in navigating the diverse landscape of business financing in Canada.",
      whatList:[
        "Accountax works closely with you to assess your unique business needs and crafts financing solutions that align with your goals and vision.",
        "We provide insightful advice on selecting the most suitable financing options, considering your business model, industry, and future plans.",
        "Accountax streamlines the financing process, leveraging technology to expedite approvals and funding. This efficiency ensures you can seize opportunities without unnecessary delays.",
        "We help you navigate the potential risks associated with various financing options, ensuring that you make informed decisions aligned with your long-term objectives.",    
      ]
    },
    faq:null,
    serviceName:"Business Financing",
    slug:"/business-financing"
  },
  {
    bannerImg:"/images/creative/Corporate-Taxes-banner.webp",
    thumbnailImg:"/images/creative/Corporate-Taxes-2.webp",
    ServicesInnerTextContent:{
      heading:"Corporate Taxes",
      content:"Understanding corporate taxes in Canada can be tricky. That's why you need a partner who knows the ropes and has the insight to guide you through.",
      emphasisText:"",
      whatIntro:null,
      whatList:null
    },
    faq:null,
    serviceName:"Corporate Taxes",
    slug:"/corporate-taxes"
  },
  {
    bannerImg:"/images/creative/Business-Registration-Banner.webp",
    thumbnailImg:"/images/creative/Business-Registration-2.webp",
    ServicesInnerTextContent:{
      heading:"Strategic Corporate Tax Solutions",
      content:"Accountax International is your trusted ally, committed to providing strategic Corporate Tax Solutions that optimize your tax position while ensuring compliance with the changing tax regulations. Strategic tax planning allows businesses to optimize their tax liabilities, ensuring that they pay their fair share while taking advantage of available deductions and incentives. Staying abreast of the constantly changing tax regulations is crucial to avoid penalties and legal complications. Corporate Tax Solutions ensure that your business complies with the latest tax laws. We provide expert guidance on navigating the complexities of tax regulations to optimize your business's financial position. Efficient tax management contributes to the overall financial health of your business. It allows for better budgeting, improved cash flow, and increased profitability.",
      emphasisText:"Corporate taxes encompass the levies imposed on the profits earned by businesses operating in Canada. These taxes are a critical component of a company's financial obligations and require meticulous attention to detail to maximize deductions and minimize liabilities.",
      whatIntro:"Trust us to be your partner in achieving financial efficiency, legal compliance, and strategic tax optimization.",
      whatList:[
        "Our team of experienced tax professionals possesses in-depth knowledge of Canadian corporate tax laws.",
        "Accountax works collaboratively with your team to develop a customized corporate tax strategy by identifying opportunities for tax credits, deductions, and incentives.",
        "We ensure that your corporate tax filings are accurate, timely, and compliant with all relevant regulations.",
        "We minimize the risk of audits and associated penalties.",
        "Accountax leverages cutting-edge technology to streamline the corporate tax process. This enhances efficiency and reduces errors, ensuring precision in your tax filings.",
      ]
    },
    faq:null,
    serviceName:"Business Registration",
    slug:"/business-registration"
  },
  {
    bannerImg:"/images/creative/Corporate-Administration-Banner.webp",
    thumbnailImg:"/images/creative/Corporate-Administration-2.webp",
    ServicesInnerTextContent:{
      heading:"Streamlined Corporate Administrative Services",
      content:"Running a successful business involves more than just financial considerations. Well-managed administrative processes streamline day-to-day operations, reducing bottlenecks and ensuring that your business runs smoothly. Corporate Administrative Services help in monitoring and ensuring compliance with various regulations, minimizing the risk of legal complications. Outsourcing administrative tasks allows your internal team to focus on core business activities, optimizing resources and enhancing productivity.\n Accountax International recognizes the importance of efficient Corporate Administrative Services to support your operations. Accountax understands that every business is unique. Our dedicated team is committed to providing streamlined administrative solutions, allowing you to focus on what you do best – growing your business. Trust us to be your ally in achieving operational excellence and maintaining compliance, allowing you to focus on your core business objectives.",
      emphasisText:"Corporate Administrative Services encompass a broad range of non-financial tasks vital for the smooth functioning of your business. These services include record-keeping, document management, compliance monitoring, and other administrative functions that contribute to overall organizational efficiency.",
      whatIntro:"Partnering with Accountax International for Corporate Administrative Services ensures that your business operates seamlessly, with administrative tasks managed efficiently.",
      whatList:[
        "We offer personalized Corporate Administrative Services tailored to meet the specific needs and goals of your organization.",
        "Our team assists in efficient document organization, retrieval, and storage, ensuring that your critical information is readily available when needed.",
        "We keep a vigilant eye on regulatory changes and updates, helping your business stay compliant with the latest administrative requirements.",
        "Accountax leverages technology to streamline administrative processes, enhancing efficiency and reducing the burden on your internal resources.",
    
      ]
    },
    faq:null,
    serviceName:"Corporate Administrative Services",
    slug:"/corporate-administrative-service"
  },
  {
    bannerImg:"/images/creative/International-Business-Banners.webp",
    thumbnailImg:"/images/creative/International-Business-2.webp",
    ServicesInnerTextContent:{
      heading:"International Business Operations in Canada",
      content:"Expanding your international business to Canada presents a number of opportunities and challenges. Adhering to Canadian laws and regulations is critical. International businesses must navigate federal, provincial, and municipal regulations to ensure legal compliance. Understanding the Canadian tax system is crucial for international businesses. Efficient tax planning and compliance are vital to minimize tax liabilities and ensure financial sustainability. Successful international businesses in Canada understand and adapt to the local culture. This includes customer preferences, business etiquette, and workforce dynamics.\n Accountax International is your dedicated partner, offering comprehensive solutions to help navigate the complexities of conducting business in the Canadian market. Whether you are entering the Canadian market or expanding your existing operations, rely on us for expert guidance and comprehensive support to ensure your success",
      emphasisText:"International Business Operations in Canada involve establishing and managing a business presence in the Canadian market. This encompasses compliance with Canadian regulations, understanding local business practices, and optimizing operations to thrive in the Canadian competitive business market.",
      whatIntro:"Accountax International is your trusted partner in navigating the complexities of international business operations in Canada.",
      whatList:[
        "Our team provides expert guidance on navigating the legal landscape, ensuring your international business adheres to all relevant regulations and stays in good standing.",
        "Accountax specializes in international tax planning, helping your business optimize its tax position and take advantage of available incentives while ensuring compliance with Canadian tax laws.",
        "Accountax offers insights into local business practices, helping your international business build strong relationships and integrate seamlessly into the Canadian market.",
        "Accountax provides strategic advice to enhance your business's competitiveness in the Canadian market, taking into account industry trends and market dynamics.",
    
      ]
    },
    faq:null,
    serviceName:"International Business",
    slug:"/international-business"
  },
]


function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Navigate to="/" />} />
        <Route path="/about" element={<About />} />
        <Route path="/service" element={<Service />} />
        <Route path="/blog" element={<BlogMain />} />
        <Route path="/news/:id" element={<BlogInner />} />
        
        <Route path="/careers" element={<Careers />} />
        <Route path="/contact" element={<Contact />} />

        {
          individualServices.map((ele)=>{
              return(
                <Route path={`/${ele.slug}`} element={<ServiceInner indData={ele} />} />
              )
          })
        }

        
        <Route path="/admin" element={<AdminScreen />} />
        <Route path="/admin/blogs" element={<BlogsScreen />} />
        <Route path="/login" element={<LoginScreen />} />
        <Route path='/location/:slug' element={<LocationPages />} />
        <Route path='/admin/location-pages' element={<LocationScreen />}/>
        
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer/>
    </>
  );
}

export default App;
