import React from 'react'
import "./OnlineServices.css"
import ServiceCard from './ServiceCard'

function OnlineServices() {

    const serviceData = [
      {
        img: "/images/icons/service-book-keeping-icon.svg",
        title: "Bookkeeping",
        anchor:"/bookkeeping",
        content:
          "We ensure that your financial records are not just accurate but readily available for your business's strategic decision-making.",
      },
      {
        img: "/images/icons/service-accounting-icon.svg",
        title: "Accounting",
        anchor:"/accounting-solutions",
        content:
          "Our experienced chartered accountants bring decades of expertise to manage all aspects of your accounting needs.",
      },
      {
        img: "/images/icons/service-payroll-icon.svg",
        title: "Payroll Management",
        anchor:"/payroll-management",
        content:
          "Accountax International Inc. takes the burden off your shoulders, ensuring that your employees are paid accurately and on time.",
      },
      {
        img: "/images/icons/service-hst-returns-icon.svg",
        title: "HST Returns",
        anchor:"/hst-returns",
        content:
          "Our team specializes in managing your HST returns efficiently, ensuring compliance and identifying opportunities for savings within tax regulations.",
      },
      {
        img: "/images/icons/service-corporate-taxes-icon.svg",
        title: "Corporate Taxes Canadian and US taxes",
        anchor:"/corporate-taxes",
        content:
          "Accountax International Inc. brings decades of expertise to the table, ensuring that your corporate tax matters are managed with utmost proficiency.",
      },
      {
        img: "/images/icons/service-personal-taxes-icon.svg",
        title: "Personal Taxes- Canadian and US taxes",
        anchor:"/personal-tax",
        content:
          "For over 50 years individuals and businesses have relied on Accountax International Inc.",
      },
      {
        img: "/images/icons/service-business-financing-icon.svg",
        title: "Business Financing Solutions",
        anchor:"/business-financing",
        content:
          "At Accountax International Inc., we specialize in providing tailored business financing solutions designed to meet the unique needs of your enterprise.",
      },
      {
        img: "/images/icons/service-business-registrations-icon.svg",
        title: "Business Registrations – Sole Proprietor, PArtnerships and Corporations",
        anchor:"/business-registration",
        content:
          "Our expert team guides you through the intricacies of business registration, helping you make informed decisions that align with your goals.",
      },
      {
        img: "/images/icons/service-corporate-administrative-icon.svg",
        title: "Corporate Administrative Services- Shareholders Agreements, By-Laws, Share Certificates, Minute Books maintenance.",
        anchor:"/corporate-administrative-service",
        content:
          "Running a successful requires efficient corporate administration. Accountax International Inc. offers comprehensive corporate administrative services to streamline your operations.",
      },
      {
        img: "/images/icons/service-international-businesses-icon.svg",
        title: "International Businesses doing Business in Canada.",
        anchor:"/international-business",
        content:
          "With a deep understanding of both global and local businesses, we offer specialized services to ensure your international venture thrives in the Canadian market.",
      },
    ];

  return (
    <div className='online-service-container standard-padding-space '>
        <div className='text-content text-center mb-4'>
            <h3 className='body-heading-b'>Comprehensive Accounting </h3>
            <h3 className='body-heading-b'>& <span>Financial Solutions</span></h3>
        </div>
        <div className='container mt-5'>
            <div className='service-list row gy-4 d-flex justify-content-center'>
                {serviceData.map((ele)=>{
                   return <ServiceCard img={ele.img} title={ele.title} content={ele.content} anchor={ele.anchor}/>
                })}
            </div>
        </div>
    </div>    
  )
}

export default OnlineServices