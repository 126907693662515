import React from 'react'
import "./WorkIndustry.css"
import IndustryCard from './IndustryCard'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import {Autoplay} from "swiper/modules"

const industryData = [
  { img: "/images/icons/industry-icon-food.svg", title: "Food and Beverage" },
  {
    img: "/images/icons/industry-icon-automotive.svg",
    title: "Automotive Accounting",
  },
  { img: "/images/icons/industry-icon-moving.svg", title: "Moving Services" },
  { img: "/images/icons/industry-icon-security.svg", title: "Security Services" },
  { img: "/images/icons/industry-icon-it.svg", title: "IT" },
  { img: "/images/icons/industry-icon-non-profit.svg", title: "Non-Profit" },
  { img: "/images/icons/industry-icon.svg", title: "Distributors" },
  {
    img: "/images/icons/industry-icon-marketing.svg",
    title: "Marketing and Advertising",
  },
  { img: "/images/icons/industry-icon-on-retail.svg", title: "Online Retailers" },
  { img: "/images/icons/industry-icon-retail.svg", title: "Retail" },
  { img: "/images/icons/industry-icon-land.svg", title: "Landscaping" },
  { img: "/images/icons/industry-icon-construction.svg", title: "Construction" },
  { img: "/images/icons/industry-icon-engineering.svg", title: "Engineering" },
  { img: "/images/icons/industry-icon-manufacture.svg", title: "Manufacturing" },
];


function WorkIndustry() {

    const handlePrevButtonClickInd = () => {
        const swiperInstance = document.querySelector('#ind-cards-slider').swiper;
        swiperInstance.slidePrev();
      };
      
      const handleNextButtonClickInd = () => {
        const swiperInstance = document.querySelector('#ind-cards-slider').swiper;
        swiperInstance.slideNext();
      };
  return (
    <div className='work-industry-container text-center standard-padding-space'>
        <div className='container my-3'>
        <h3 className='body-heading-b mx-auto'>These are just some of the </h3>
        <h3 className='body-heading-b mx-auto'><span>Industries we work with</span></h3>
        <div className='industry-list container mt-5'>
            {/* <div className='row'>
                {industryData.map((ele)=>{
                    return <IndustryCard img={ele.img} title={ele.title} />
                })}
            </div> */}
            <Swiper
            spaceBetween={30}
            autoplay={{
              delay: 1500,
              disableOnInteraction: false,
            }}
            loop={true}
            modules={[Autoplay]}
            navigation={{
              nextEl: '.swiper-button-next-ind',
              prevEl: '.swiper-button-prev-ind',
            }}
            breakpoints={{
                200:{
                  slidesPerView: 1,
                },
                550: {
                  slidesPerView: 2,
                },
                800: {
                  slidesPerView: 3,
                },
                1200: {
                  slidesPerView: 4,
                },
                1400:{
                    slidesPerView:5,
                }
              }}
            pagination={{ clickable: true }}
            observer ={true}
            observeParents={true}
            parallax={true}
            className="ind-list pt-4"
            style={{position:"relative"}}
            id="ind-cards-slider"
            >
                  <div className="swiper-button-prev-ind" onClick={handlePrevButtonClickInd}>
                        <img src="/images/icons/indus-carousel-arrow-left.png" className="arrow-img"/>
                    </div>
              {
                industryData.map((ele,ind)=>{
                  return(<>
                    <SwiperSlide key={ind}>
                      <IndustryCard
                        img={ele.img}
                        title={ele.title}
                      />
                    </SwiperSlide>
                     </>)
                })
              }
                    <div className="swiper-button-next-ind" onClick={handleNextButtonClickInd}>
                        <img src="/images/icons/indus-carousel-arrow-right.png" className="arrow-img"/>
                    </div>
        </Swiper>
        
        </div>


        </div>
    </div>
  )
}

export default WorkIndustry