import React, { useState,useEffect,useRef } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./Header.css";

const Header = (props) => {
  const [menuState, setMenuState] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isDropdownOpenDesk, setIsDropdownOpenDesk] = useState(false);
  const [intialLoad , setIntialLoad] = useState(false)
  const navigate = useNavigate()
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target) && isDropdownOpenDesk) {
        if (!event.target.closest('.app')) {
          setIsDropdownOpenDesk(false);
        }
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isDropdownOpenDesk]);

  const clickCountRef = useRef(0);

const onClickService = (e) => {
    e.preventDefault();
    
      clickCountRef.current++;
      if (clickCountRef.current === 1) {
        setIsDropdownOpen(true);
      } 
      else if (clickCountRef.current === 2) {
        setMenuState(false)
        setIsDropdownOpen(false)
        navigate("/service");
        clickCountRef.current = 0;
      }
  };

  const menuClick = () => {
    setMenuState((prev) => !prev);
  };

  return (
    <div className="header" style={props.seprateHeader ? {background:"silver"}:{background:'transparent'}}>
      <div className="header-wrapper container">
        <nav className="navbar d-md-flex flex-md-row d-block">
          <nav className="navbar-container py-2">
            <div className="nav-elements d-flex justify-content-between align-items-center">
              <input className="d-none" type="checkbox" id="menu-toggle" />
              <img src="/images/icons/logo.png" className="nav-logo" style={{cursor:"pointer"}} onClick={()=>{navigate("/home")}}/>
              <nav>
                <ul
                  className="nav-list d-lg-flex gap-xl-4 gap-2 align-items-center d-none main-nav"
                  id="js-menu"
                >
                  <li>
                    <Link to={"/home"} className="nav-links" style={props.darkBg ? {}:{}}>
                      HOME
                    </Link>
                  </li>
                  <li>
                    <Link to={"/about"} className="nav-links" style={props.darkBg ? {}:{}}>
                      ABOUT US
                    </Link>
                  </li>
                 
                  
                  <div className="navigation-dropdown" ref={dropdownRef}>
                  <li onMouseEnter={()=>{setIsDropdownOpenDesk(true)}} >

                      <div className="dropdown-trigger d-flex align-items-center">
                          <Link to={"/service"} className="nav-links" onClick={()=>{setIsDropdownOpenDesk(false)}} style={props.darkBg ? {}:{}}>SERVICES</Link>
                          <img src={`/images/icons/drop-down-icon-${props.darkBg ? "w":"b"}.png`} className={isDropdownOpenDesk ? "rotate-icon arrow-icon":"rotate-back arrow-icon" }/>
                      </div>
                      <div className={`dropdown-content ${isDropdownOpenDesk ? 'open' : 'none-delay'}`} onMouseEnter={()=>{setIsDropdownOpenDesk(true)}} onMouseLeave={()=>{setIsDropdownOpenDesk(false)}}>
              
                        <Link to={"/bookkeeping"} className="nav-links" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                          <p className="mb-0">Bookkeeping</p>
                        </Link>
                        <Link to={"/accounting-solutions"} className="nav-links" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                          <p className="mb-0">Accounting</p>
                        </Link>
                        <Link to={"/payroll-management"} className="nav-links" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                          <p className="mb-0">Payroll</p>
                        </Link>
                        <Link to={"/hst-returns"} className="nav-links" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                          <p className="mb-0">GST/HST Returns</p>
                        </Link>
                        <Link to={"/corporate-taxes"} className="nav-links" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                          <p className="mb-0">Corporate Taxes</p>
                        </Link>
                        <Link to={"/personal-tax"} className="nav-links" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                          <p className="mb-0">Personal Taxes</p>
                        </Link>
                        <Link to={"/business-financing"} className="nav-links" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                          <p className="mb-0">Business Financing</p>
                        </Link>
                        <Link to={"/business-registration"} className="nav-links" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                          <p className="mb-0">Business Registrations</p>
                        </Link>
                        <Link to={"/corporate-administrative-service"} className="nav-links" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                          <p className="mb-0">Corporate Administrative</p>
                        </Link>
                        <Link to={"/international-business"} className="nav-links" onClick={()=>{setIsDropdownOpenDesk(false)}}>
                          <p className="mb-0">International Businesses</p>
                        </Link>
                      </div>
                    </li>
                    </div>
                    <li>
                    <Link to={"/careers"} className="nav-links" style={props.darkBg ? {}:{}}>
                      CAREERS
                    </Link>
                  </li>
                  <li>
                    <Link to={"/blog"} className="nav-links" style={props.darkBg ? {}:{}}>
                      INSIGHTS
                    </Link>
                  </li>
                  <li>
                    <Link to={"/contact"} className="nav-links" style={props.darkBg ? {}:{}}>
                      CONTACT US
                    </Link>
                  </li>
                </ul>
              </nav>
                  <div className="nav-buttton mx-3 d-lg-flex d-none gap-4 extras-btns">
                   
                    <div className="social-container d-flex align-items-center justify-content-center gap-2">
                        <img src="/images/icons/header-fb-icon.svg" alt="" />
                        <img src="/images/icons/header-linked-icon.svg" alt="" />
                        <img src="/images/icons/header-twitter-icon.svg" alt="" />
                    </div>
                  </div>

              <label
                value="menu-toggle"
                onClick={menuClick}
                className="responsive-menu-btn d-lg-none d-inline"
              >
                <img
                  src="/images/icons/icons8-hamburger-menu-50.png"
                  className="img-fluid menu-btn"
                  alt="menu"
                />
              </label>

             
            </div>
          </nav>

          {/* Dropdown in responsive mode */}
          <nav className="responsive-popup-menu w-100 d-lg-none pb-4" style={{ display: menuState ? "block" : "none" }}>
            <ul
              className="resp-nav-list w-100 d-flex flex-column gap-2 hs-dropdown [--trigger:hover] pl-4 py-3"
              
            >
              <li>
                <Link to={"/home"} className="nav-links">
                  HOME
                </Link>
              </li>
              <li>
                <Link to={"/about"} className="nav-links">
                  ABOUT Us
                </Link>
              </li>
             
              <li>

            <div className="navigation-dropdown ">
              <div className="dropdown-trigger d-flex align-items-center" style={{color:"black"}} onClick={onClickService} >
                  <a className="nav-links " >SERVICES</a>
                  <img src='/images/icons/drop-down-icon-b.png' className={isDropdownOpen ? "rotate-icon":"rotate-back" }/>
              </div>
              <div className={`dropdown-content ${isDropdownOpen ? 'open' : 'd-none'}`} style={{top:"85%",left:"-20%" }}>
                
                <Link to={"/bookkeeping"} className="nav-links" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                  <p className="mb-0">Bookkeeping</p>
                </Link>
                <Link to={"/accounting-solutions"} className="nav-links" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                  <p className="mb-0">Accounting</p>
                </Link>
                <Link to={"/payroll-management"} className="nav-links" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                  <p className="mb-0">Payroll</p>
                </Link>
                <Link to={"/hst-returns"} className="nav-links" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                  <p className="mb-0">GST/HST Returns</p>
                </Link>
                <Link to={"/corporate-taxes"} className="nav-links" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                  <p className="mb-0">Corporate Taxes</p>
                </Link>
                <Link to={"/personal-tax"} className="nav-links" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                  <p className="mb-0">Personal Taxes</p>
                </Link>
                <Link to={"/business-financing"} className="nav-links" onClick={()=>{setIsDropdownOpen(false); setMenuState(false)}}>
                  <p className="mb-0">Business Financing</p>
                </Link>
                <Link to={"/business-registration"} className="nav-links" onClick={()=>{setIsDropdownOpen(false)}}>
                  <p className="mb-0">Business Registrations</p>
                </Link>
                <Link to={"/corporate-administrative-service"} className="nav-links" onClick={()=>{setIsDropdownOpen(false)}}>
                  <p className="mb-0">Corporate Administrative</p>
                </Link>
                <Link to={"/international-business"} className="nav-links" onClick={()=>{setIsDropdownOpen(false)}}>
                  <p className="mb-0">International Businesses</p>
                </Link>
              </div>
            </div>
            </li>
            <li>
                <Link to={"/careers"} className="nav-links">
                  CAREERS
                </Link>
              </li>
              <li>
                <Link to={"/blog"} className="nav-links">
                  INSIGHTS
                </Link>
              </li>
              <li>
                <Link to={"/contact"} className="nav-links">
                  CONTACT US
                </Link>
              </li>
             
            </ul>
                <div className="nav-buttton mt-2 flex-wrap d-flex gap-4 extras-btns">
                <div className="social-container-y d-flex align-items-center justify-content-center gap-2">
                      <img src="images/icons/header-fb-icon.svg" alt="" />
                        <img src="images/icons/header-linked-icon.svg" alt="" />
                        <img src="images/icons/header-twitter-icon.svg" alt="" />
                    </div>
                </div>
          </nav>
        </nav>
      </div>
    </div>
  );
};

export default Header; 