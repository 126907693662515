import React from 'react'
import "./ImageSlideContent.css"
import { useNavigate } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import {Autoplay} from "swiper/modules"

function ImageSlideContent({
    bgColor,
    shiftRow=false,
    heading="",
    subHeading="",
    content="",
    img,
    btn=false,
    btnText="Read More",
    btnRef="/",
    btnYellow=false

}) {
    const paragraphs = content.split('\n').map((paragraph, index) => <p key={index} className='content body-paragraph mb-3'>{paragraph}</p>);
    const navigate = useNavigate()
  return (
    <div className='ImageSlideContent-container standard-padding-space mb-4' style={{backgroundColor: bgColor}}>
        <div className='container my-5'>
            <div className={`row d-flex ${ shiftRow ? "flex-row-reverse" : ""} align-items-center justify-content-center gy-4`}>
                <div className='col-md-6 h-100 my-auto px-4'>
                    
                    <Swiper
                        spaceBetween={20}
                        autoplay={{
                        delay: 1500,
                        disableOnInteraction: false,
                        loopFillGroupWithBlank: true,
                        }}
                        loop={true}
                        modules={[Autoplay]}
                        breakpoints={{
                            200:{
                            slidesPerView: 1,
                            },
                        }}
                        className="w-100 px-1"
                    >
                        {
                            img.map((ele)=>{
                                return(
                                    <SwiperSlide >
                                        <img src={ ele} className='img-fluid' />
                                    </SwiperSlide>
                                )
                            })
                        }
                        
                    </Swiper>
                </div>
                <div className='col-md-6'>
                    <div className='text-content d-flex flex-column align-items-start justify-content-center gap-lg-2 h-100 px-2 px-md-0'>
                        {subHeading == "" ? <></> :<h5 className='sub-title'>{ subHeading}</h5>}
                        <h3 className='heading body-heading-b'>{ heading}</h3>
                        <p className='content body-paragraph'>{ paragraphs}</p>
                        {btn && <button className={`genral-btn ${btnYellow && "btn-y"} mt-2`} onClick={()=>{navigate(btnRef)}}>{btnText}</button>}
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default ImageSlideContent